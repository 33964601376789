import React, { useState, useContext } from "react";
import {
  Alert, Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//images
import waqrcode from "../../assets/images/iabaduu/qrcode-wa-business.jpg";

const UserSupport = props => {
  // Set meta title
  document.title = "Load | UDI";  
  return (
  <React.Fragment>
        <div className="page-content">
         <Container fluid>
                {/* Render Breadcrumb */}
                <Breadcrumbs title="Utente" breadcrumbItem="Supporto" />

                <Row>
            <Col xs={12}>
              <Card>
                  <CardBody>
                        <Row>
                            <Col xs={6}>
                            <div className="d-flex align-items-center hstack gap-3">
                                {/*
                                <p>
                                https://wa.me/390240709003
                                </p>
                                */}
                                <h5>Scansiona con il telefono il QRcode per chattare con un nostro operatore</h5>
                               
                            </div>
                            <div >
                                
                                <p>o invia un e-mail a <a href="mailto:supporto@medstock.it"></a>supporto@medstock.it</p>
                            </div>
                            </Col>
                            <Col xs={6}>
                            <div className="d-flex align-items-center hstack gap-3">
                                    <img className="img-fluid" src={waqrcode} alt="whatsapp"></img>
                                </div>
                            </Col>
                        </Row>
                    
                  </CardBody>
              </Card>
            </Col>
          </Row>


         </Container>
         </div>    
  </React.Fragment>
  );  
};

export default UserSupport;
