import React, { useState } from "react";

// The Context 
const GlobalDataContext = React.createContext();
 const GlobalDataProvider = props => {
    const [magazzino, setMagazzino] = useState("");
    const [nomiProdotti, setNomiProdotti] = useState("");

    return (
        <GlobalDataContext.Provider
            value={{ magazzino, setMagazzino, nomiProdotti, setNomiProdotti }}
        >
            {props.children}
        </GlobalDataContext.Provider>
    );
};
 export { GlobalDataProvider, GlobalDataContext }
