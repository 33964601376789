//import React from "react";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, CardBody, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import { Link, NavLink } from "react-router-dom";

// import images
import logo from "../../../assets/images/medstock-logo_scritta-vert.png";
import logofull from "../../../assets/images/logo-MedStock-full.jpg";
//Import Countdown
import Countdown from "react-countdown";





const Section = props => {

    // modal
    const [subscribeModal, setSubscribeModal] = useState(false);
    // modal
    useEffect(() => {
      setTimeout(() => {
        setSubscribeModal(true);
      }, 2000);
    }, []);


  

  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        <div className="bg-overlay bg-dark"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="text-white-50">
                <h1 className="text-white fw-semibold mb-3 hero-title">MedStock <br />è il software online, semplice e completo per la gestione del magazzino UDI </h1>
                <p className="font-size-14">Il servizio SAAS (Software As A Service) per la registrazione e la conservazione dei codici UDI dei dispositivi medicali</p>

                <div className="d-flex flex-wrap gap-2 mt-4">
                  <Button href="#sottoscrizione" className="btn btn-success">Prova MedStock</Button>
                  <Button href="#features" className="btn btn-light">Come funziona?</Button>
                </div>
              </div>
            </Col>
            <Col lg={5} md={8} sm={10} className="ms-lg-auto">
            <img className="me-2" width="400" src={logo} alt="logo"></img>
            </Col>
          </Row>
        </Container>
      </section>


      {/* subscribe ModalHeader */}
      <Modal isOpen={subscribeModal} role="dialog" autoFocus={true} centered data-toggle="modal"
        toggle={() => { setSubscribeModal(!subscribeModal); }} >
        <div>
          <ModalHeader className="border-bottom-0" toggle={() => { setSubscribeModal(!subscribeModal); }}></ModalHeader>
        </div>
        <ModalBody>
                        
                          <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">                                    
                                  <Row>
                                        <Col className="col-7">
                                        <div className="text-primary p-4">
                                            <h2>10 giorni gratis</h2>
                                            <p>poi €280.00 + IVA all'anno</p>
                                            <p>Canone annuale servizio gestione magazzino UDI</p>
                                           
                                        </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                        <img src={logofull} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                        </Card>
                      <div className="text-center mb-5">
                      <a href="#sottoscrizione" 
                         className="btn btn-primary stretched-link" 
                         onClick={() => {
                            setSubscribeModal(false);
                          }}>Prova MedStock</a>     
                      </div>
        </ModalBody>
      </Modal>



    </React.Fragment>
  );
};

export default Section;
