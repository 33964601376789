import React from "react"
import { Container, Row, Card, CardBody, Col } from "reactstrap"


const CardsMini = () => {
  
  return (
    <React.Fragment>
      <section className="section bg-white p-0">
        <Container>
          <div className="currency-price">
            <Row>
              {/* reder card boxes */}
             
              <Col md="4">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                      <span
                          className={ "avatar-title rounded-circle font-size-18"}
                                          >
                          <i className="bx bx-lock-alt nav-icon d-block"/>
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted">MedStock </p>
                        <h5>CONSERVAZIONE</h5>
                        <p className="text-muted text-truncate mb-0">
                          a norma di legge
                          <i
                            className="mdi mdi-check ms-1 text-success"
                          />
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                        <span
                          className={ "avatar-title rounded-circle font-size-18"}
                                          >
                         <i className="bx bx-barcode nav-icon d-block"/>
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted">MedStock</p>
                        <h5>INSERIMENTO</h5>
                        <p className="text-muted text-truncate mb-0">
                          da QR o manuale
                          <i
                            className="mdi mdi-check ms-1 text-success"
                          />
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                        <span
                          className={ "avatar-title rounded-circle font-size-18"}
                                          >
                          <i className="bx bx-cloud nav-icon d-block"/>
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted">MedStock</p>
                        <h5>DISPONIBILITA</h5>
                        <p className="text-muted text-truncate mb-0">
                          sempre e ovunque 
                          <i
                            className="mdi mdi-check ms-1 text-success"
                          />
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>




            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default CardsMini
