import PropTypes from "prop-types";
import React, { useState } from 'react';
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/iabaduu/MedStock-logo-plain.svg";

//AX
import { updatePassword } from 'aws-amplify/auth';
import { useFormik } from "formik";


const ChangePasswordPage = props => {

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleUpdatePassword = async (oldPass, newPass) => {
    try {
      await updatePassword({ oldPassword: oldPass, newPassword: newPass });
      setMessage('Password updated successfully!');
      setIsError(false);
    } catch (err) {
      console.error(err);
      setMessage(err.message);
      setIsError(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleUpdatePassword(oldPassword, newPassword);
    setOldPassword('');
    setNewPassword('');
  };

  //-----
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Cambia pw</h5>
                        <p>procedi</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                   <div>
                      <Row className="mb-3">
                        <Col className="text-end">
                       
                        <form onSubmit={handleSubmit}>
                          <div>
                            <label>Inserisci la vecchia Password:</label>
                            <input
                              type="password"
                              value={oldPassword}
                              onChange={e => setOldPassword(e.target.value)}
                              required
                            />
                          </div>
                          <div>
                            <label>Inserisci la nuova Password:</label>
                            <input
                              type="password"
                              value={newPassword}
                              onChange={e => setNewPassword(e.target.value)}
                              required
                            />
                          </div>
                          <button type="submit">Update Password</button>
                          {message && <p style={{ color: isError ? 'red' : 'green' }}>{message}</p>}
                        </form>




                        </Col>
                      </Row>
                  </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  torna {" "}
                  <Link to="/" className="font-weight-medium text-primary">
                    indietro
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} MedStock{" "}
                  <i className="mdi mdi-barcode text-danger" /> by iabaduu
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};



export default withRouter(ChangePasswordPage);
