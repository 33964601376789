import React, { useState, useRef } from "react";
import {
  Container, Row, Col, Card, CardBody, CardTitle, Input, FormGroup, Label
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//
import ImageMapper from 'react-img-mapper';
//images
import viso from "../../assets/images/iabaduu/viso-medstock.jpg";
//json mappa viso
import json from "./viso-map.json";
//mobile
import {BrowserView, MobileView} from 'react-device-detect';
import useScreenSize from "./useSize";




const TrattamentoViso = (props) => {

  const screenSize = useScreenSize();
  //meta title
  document.title = "Magazzino | UDI";

  const URL = viso;
  const MAP = {
    name: 'my-map',
    areas: json,
  };

  const [selectedAreas, setSelectedAreas] = useState([]);
  const imageMapperRef = useRef(null);

  const handleAreaClick = (area) => {
    setSelectedAreas(prevSelected => {
      if (prevSelected.includes(area.id)) {
        if (imageMapperRef.current) {
          imageMapperRef.current.clearHighlightedArea(area.id);
        }
        return prevSelected.filter(id => id !== area.id);
      } else {
        return [...prevSelected, area.id];
      }
    });
  };

  const handleCheckboxClick = (areaId) => {
    setSelectedAreas(prevSelected => {
      if (prevSelected.includes(areaId)) {
        if (imageMapperRef.current) {
          imageMapperRef.current.clearHighlightedArea(areaId);
        }
        return prevSelected.filter(id => id !== areaId);
      } else {
        return [...prevSelected, areaId];
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("Trattamento")} breadcrumbItem={props.t("Trattamento viso")} />

          {/* START  ------------  content*/}
          <Row>
            <Col xl="8">
            <BrowserView>
              <Card>
                <CardBody>
                  <ImageMapper
                    ref={imageMapperRef}
                    src={URL}
                    map={MAP}
                    // stayHighlighted={selectedAreas} // highlight based on selected areas
                    stayHighlighted={props.stayHighlighted} // dynamic stayHighlighted
                    stayMultiHighlighted={true} // enable multiple highlights
                    toggleHighlighted={true} // enable toggle highlightings
                    onClick={handleAreaClick}
                    responsive={true} // enable responsive behavior
                    parentWidth={500} // dynamic parentWidth
                  />
                </CardBody>
              </Card>
              </BrowserView>
              <MobileView>
              <ImageMapper
                    ref={imageMapperRef}
                    src={URL}
                    map={MAP}
                    // stayHighlighted={selectedAreas} // highlight based on selected areas
                    stayHighlighted={props.stayHighlighted} // dynamic stayHighlighted
                    stayMultiHighlighted={true} // enable multiple highlights
                    toggleHighlighted={true} // enable toggle highlightings
                    onClick={handleAreaClick}
                    responsive={true} // enable responsive behavior
                    parentWidth={screenSize.width} // dynamic parentWidth
                  />
              </MobileView>
            </Col>
            <Col xl="4">
              {/* Checkboxes */}
              <Card>
                <CardBody>
                  {MAP.areas.map(area => (
                    <FormGroup check key={area.id}>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={selectedAreas.includes(area.id)}
                          onClick={() => handleCheckboxClick(area.id)}
                        /> {area.title}
                      </Label>
                    </FormGroup>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(TrattamentoViso);
