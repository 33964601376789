import { formatDate } from "@fullcalendar/core";

// udisplit.js  
function UdiSplit(udi) {
    console.log('UDISPLIT');
    //var udi = $('#UDI').value;
    //var udi = ')01=07640173234012)10=22392JL0)17=241031)21=22392JL05445';
    //var udi = document.getElementById("UDI").value;
    var sUDI, sGTIN, aSCAD, sLOTTO, sSERIALE, DATACARICO;
    console.log('udi', udi);
    if(udi != '') {
        sUDI = String(udi);
        // GTIN 01 
        var regexGTIN = /(?<=\)01=)(.{14}?)/gm;
        sGTIN = String(udi).match(regexGTIN);
        // DataScad 17
        var regexSCAD = /(?<=\)17=)(.{6}?)/gm;
        var sSCAD = String(udi).match(regexSCAD);
        // Lotto 10
        var regexLOTTO = /(?<=\)10=)(.*?)((?=$)|(?=\)))/gm;
        sLOTTO = String(udi).match(regexLOTTO);
        // Seriale 21
        var regexSERIALE = /(?<=\)21=)(.*?)((?=$)|(?=\)))/gm;
        sSERIALE =  String(udi).match(regexSERIALE);
        //compilo la form con i dati ricavati dal QR
        var preyear = "20";
        if (sSCAD !== null) {
            aSCAD = preyear+sSCAD.toString().substring(4,6)+'-'+ sSCAD.toString().substring(2,4)+'-'+sSCAD.toString().substring(0,2);
        }

        //precompilo data
        let DATACARICO = new Date().toJSON().slice(0, 10);

        return {sUDI, sGTIN, aSCAD, sLOTTO, sSERIALE, DATACARICO};
    }
}

function UdiCompose(gtin, scadenza, lotto, seriale) {
    scadenza = scadenza.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$3$2$1").replace(/-/g, '').substring(2);
    return `01=${gtin.toString()})10=${lotto.toString()})17=${scadenza.toString()})21=${seriale.toString()}`
}
export { UdiSplit, UdiCompose };