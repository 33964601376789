import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavLink,
    TabContent,
    TabPane,
  } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import FAQs from "../Publicsite/Faqs/FAQs";
import Accordian from "../Publicsite/Faqs/accordian";

import codiceudi from "../../assets/images/iabaduu/CodiceUDI.png";



const Infoudi = () => {

    //meta title
    document.title="Info UDI";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Info UDI" breadcrumbItem="Info UDI" />
          

          <Row>
 
            <Col lg="12">
                <Card>
                <div className="d-flex align-items-center">
                        <img className="center" width="90%" src={codiceudi} alt="codiceudi"></img>   
                </div>    
                </Card>
            </Col>

          </Row>
          <Row>  
            <Col lg="6">
            <Card>
            <CardBody>
                <div className="text-muted">
                <h4>Come è fatto un codice UDI?</h4>
                <p>
                Ogni dispositivo medico viene identificato tramite un codice UDI, composto da una parte identificativa del dispositivo (UDI-DI) e una identificativa della produzione (UDI-PI), che può rappresentare il numero di serie, il numero di lotto, la data di fabbricazione e la data di scadenza. 
                </p><p>
                Questo codice è leggibile sia dalle macchine (ad esempio, attraverso codici a barre o QR code) che dall'uomo (numeri e lettere).
                </p>
               
                </div>
                </CardBody>    
                </Card>
            </Col>
            <Col lg="6">
            <Card>
                <CardBody>
                <div className="text-muted">
                
                <h4>Cosa fare se il dispositivo acquistato non presenta il codice UDI? </h4>    
                <p>
                Se il dispositivo medico acquistato possiede il codice UDI, questo deve essere registrato e conservato elettronicamente
                </p>
                <p>
                Se, invece, il dispositivo è <b>senza Codice UDI</b>, <b>non si è tenuti alla registrazione elettronica del dispositivo</b>.   
                </p>
                </div>
                </CardBody>
                </Card>
            </Col>
          </Row>

          <FAQs></FAQs>  

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Infoudi;