import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import logo from "../../assets/images/logo-MedStock-full.jpg";
import iabaico from "../../assets/images/iabaduu/iabaduu-ico250.jpg";
import Condizioni from '../../assets/pdf/MedStock-condizioni_di_fornitura.pdf';
import Trattamento from '../../assets/pdf/MedStock-trattamento_dati.pdf';

const ProductDisplay = ({ handleCheckout, isButtonDisabled, handleCheckboxChange }) => (
  <div className="account-pages my-5 pt-sm-5 text-center">
    <Container>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5}>
          <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">MedStock</h5>
                        <p>Canone annuale servizio gestione magazzino UDI</p>
                        <p>€280.00 + IVA</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                        <div className="mt-4 text-center">
                          Il servizio é riservto i soli professionisti dotati di Partita IVA.
                          <br/>
                          
                         </div>
                        <div className="form-check form-check-info mb-3 text-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="condizioniCheckbox"
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="condizioniCheckbox"
                          >
                            Accetto e sottoscrivo le <a href={Condizioni} target="_blank" rel="noreferrer">Condizioni di fornitura dei servizi</a>
                          </label>
                        </div>
                        <div className="form-check form-check-info mb-3 text-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="trattamentoCheckbox"
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="trattamentoCheckbox"
                          >
                            Accetto e sottoscrivo le <a href={Trattamento} target="_blank" rel="noreferrer">policy del trattamento dei dati</a>
                          </label>
                        </div>
                        <button className="btn btn-primary btn-block" onClick={handleCheckout} disabled={isButtonDisabled}>
                          Procedi con sottoscrizione e pagamento
                        </button>

                        <div>
                          <br/>
                          <i className="mdi mdi-alert me-1" />
                          Verrai reindirizzato al sistema di pagamento sicuro
                          <br/>
                          <i className="mdi mdi-account-key me-1" />
                          Una volta conclusa con successo la transazione riceverai via email le credenziali di accesso al sistema.
                          <br/>
                        </div>

              </CardBody>
              </Card>
                  <div className="mt-5">
                      <small> MedStock è un servizio di <a href="https://iabaduu.com">IABADUU srl</a>
                      <br />IT06511320969 via della Moscova, 40 – 20121 Milano
                      <br />info@iabaduu.com +39.02.40709003
                      <br/> © {new Date().getFullYear()} iabaduu srl</small>
                  </div>
        </Col>
      </Row>
    </Container>
  </div>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function App() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  let [isButtonDisabled, setIsButtonDisabled] = useState(true);
  let [checkboxState, setCheckboxState] = useState({
    condizioniCheckbox: false,
    trattamentoCheckbox: false,
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setCheckboxState(prevState => ({
      ...prevState,
      [id]: checked
    }));
  };

  useEffect(() => {
    setIsButtonDisabled(!(checkboxState.condizioniCheckbox && checkboxState.trattamentoCheckbox));
  }, [checkboxState]);

  const handleCheckout = async () => {
    try {
      const response = await axios.post(
        'https://llo7otx1v2.execute-api.us-east-1.amazonaws.com/staging/checkout',
        {
          lookup_key: 'takeoff',
          codice_sdi: 'AX666',
          nome: 'nomedaform',
          cognome: 'cognomedaform',
          piva: 'IT66611320969',
          line1: 'via Veloce 40', // Replace with the customer's address
          city: 'Milano',
          state: 'IT',
          postal_code: '20121',
          country: 'IT',
          email: 'emaildaform@gmail.com',
          fullname: 'fullnamedaform',
        }
      );

      if (response.data) {
        window.location.href = response.data.url;
      } else {
        setMessage('An error occurred during the checkout process.');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      setMessage('An error occurred during the checkout process.');
    }
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (!success && message === '') {
    return <ProductDisplay handleCheckout={handleCheckout} isButtonDisabled={isButtonDisabled} handleCheckboxChange={handleCheckboxChange} />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}
