
import DataTables from "datatables.net-dt";
import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
//import 'datatables.net-dt/css/jquery.dataTables.min.css';


const SimpleDataTable = props => {
    const tableRef = useRef(null);

    useEffect(() => {
        // Initialize DataTables when the component mounts
        const table = $(tableRef.current).DataTable({
            // Configuration options go here
            // Example:
            data: [
                ['Tiger Nixon', 'System Architect', 'Edinburgh', '5421', '2011/04/25', '$320,800'],
                ['Garrett Winters', 'Accountant', 'Tokyo', '8422', '2011/07/25', '$170,750'],
                // Add more data rows here
            ],
            columns: [
                { title: 'Name' },
                { title: 'Position' },
                { title: 'Office' },
                { title: 'Extn.' },
                { title: 'Start date' },
                { title: 'Salary' }
            ]
        });

        // Clean up function when the component unmounts
        return () => {
            table.destroy();
        };
    }, []); // Empty dependency array means this runs once on mount

    return (
        <div className="simple-data-table">
            <table ref={tableRef} className="display" width="100%"></table>
        </div>
    );
}

export default SimpleDataTable;
