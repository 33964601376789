import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Container, FormFeedback, Label, FormGroup, Button } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import withRouter from "components/Common/withRouter";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getUserProfile, updateUserProfile } from '../../helpers/query_helper';
// toast
import {UpdateUtenteOk, UpdateUtenteKO} from "helpers/toast_helper";
const authUser = localStorage.getItem('authUser');

if (authUser) {
  const user = JSON.parse(authUser); // Parse the string to an object
  const userId = user.sub; // Access the 'sub' field
  //console.log('User ID (sub):', userId);
} else {
  console.log('No authUser found in localStorage');
}

const UserProfile = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    sdi: '',
    nome: '',
    cognome: '',
    fullname: '',
    piva: 'IT',
    cf: '',
    cell: '',
    indirizzo: '',
    citta: '',
    state: 'IT',
    cap: '',
    email: '',
    terms: false,
    terms2: false,
    provincia: '',
  });

  const handleUpdate = async (values) => {
    try {
      // Prepare the input object, including the id field
      const campiupdate = {
        id: values.id, // Ensure id is passed
        sdi: values.sdi,
        nome: values.nome,
        cognome: values.cognome,
        piva: values.piva,
        cf: values.cf,
        cell: values.cell,
        indirizzo: values.indirizzo,
        citta: values.citta,
        cap: values.cap,
        email: values.email,
        provincia: values.provincia,
        _version: values._version,
        // Add more fields only if they are part of the mutation schema
      };
      const response = await updateUserProfile(campiupdate);
   
      if (response) {
        console.log("dati da updateUtente:", values);
        UpdateUtenteOk(`Profilo utente Aggiornato`); // totoast
      } else {
        console.error("No data returned from mutation", response.errors);
        //alert(`Error updating profile: ${response.errors[0]?.message}`);
      }
    } catch (err) {
      console.error("Error updating profile", err);
      UpdateUtenteKO(`Errore nell'aggiornamento dati`); // totoast
      //alert(`Error updating profile: ${err.message}`);
    }
  };
  
  useEffect(() => {
    const fetchUtenteData = async () => {
      try {
        console.log("Load user profile");
        const userData = await getUserProfile();

        if (userData) {
          setInitialValues({
            ...initialValues,
            ...userData, // Make sure the id is included in the initial values
          });
        } else {
          console.error("No user data returned", response);
        }
      } catch (err) {
        console.error("Error fetching user data", err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUtenteData();
  }, []); // Run only once when the component mounts
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Utente" breadcrumbItem="Profilo" />

        <Row className="justify-content-center">
          <Col lg={12}>
            <Card className="overflow-hidden">
              <CardBody className="pt-0">
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true} // Allow Formik to update initialValues when they change
                  validationSchema={Yup.object({
                    sdi: Yup.string().required("Obbligatorio").max(7).min(7),
                    nome: Yup.string().required("Obbligatorio"),
                    cognome: Yup.string().required("Obbligatorio"),
                    piva: Yup.string().required("Obbligatorio").max(13).matches(/(IT)([0-9]{11})/, "piva in formato europeo"),
                    cf: Yup.string().required("Obbligatorio").matches(/^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/gm, "errore nel codice fiscale"),
                    cell: Yup.string().required("Obbligatorio").matches(/^\+[1-9]\d{1,14}$/, "Errore nel numero, deve iniziare con +39 e contenere solo numeri"),
                    indirizzo: Yup.string().required("Obbligatorio"),
                    citta: Yup.string().required("Obbligatorio"),
                    cap: Yup.string().required("Obbligatorio").min(5).max(5).matches(/^[#0-9]+$/, "solo numerico"),
                    email: Yup.string().email("Must be a valid Email").max(255).required("Email is required"),
                  })}
                  onSubmit={handleUpdate}
                >
                  {({ isSubmitting, errors, touched, values, setFieldValue }) => {
                    useEffect(() => {
                      setFieldValue('fullname', `${values.nome} ${values.cognome}`);
                    }, [values.nome, values.cognome, setFieldValue]);

                    return (
                        //<Form onSubmit={handleUpdate}>
                        <Form>
                        <Row>
                          <Col lg="12">
                          <div className="text-center mb-5">
                              
                              <h4> Profilo utente</h4>
                              <div className="small-title">Utilizza questa form per aggiornare i tuoi dati</div>
                          </div>
                          </Col>
                      </Row>
                      <Row>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="email">Email</Label>
                                    <Field
                                       name="email"
                                       disabled
                                       type="text"
                                       className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                    />

                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="id">ID</Label>
                                    <Field
                                      name="id"
                                      disabled
                                      type="text"
                                      className={`form-control ${touched.id && errors.id ? 'is-invalid' : ''}`}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="nome">Nome</Label>
                                    <Field
                                        name="nome"
                                        placeholder="Nome"
                                        type="text"
                                        className={`form-control ${touched.nome && errors.nome ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name="nome" component={FormFeedback} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="cognome">Cognome</Label>
                                    <Field
                                        name="cognome"
                                        placeholder="Cognome"
                                        type="text"
                                        className={`form-control ${touched.cognome && errors.cognome ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name="cognome" component={FormFeedback} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Field name="fullname" type="hidden" />
                        <Row>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="sdi">Codice SDI</Label>
                                    <Field
                                        name="sdi"
                                        placeholder="Codice SDI"
                                        type="text"
                                        className={`form-control ${touched.sdi && errors.sdi ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name="sdi" component={FormFeedback} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="piva">Partita IVA</Label>
                                    <Field
                                        name="piva"
                                        placeholder="formato europeo es. IT02345678910"
                                        type="text"
                                        className={`form-control ${touched.piva && errors.piva ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name="piva" component={FormFeedback} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="cf">Codice Fiscale</Label>
                                    <Field
                                        name="cf"
                                        placeholder="CF tutto maiuscolo"
                                        type="text"
                                        className={`form-control ${touched.cf && errors.cf ? 'is-invalid' : ''}`}
                                        //style={{ textTransform: 'uppercase'}}
                                    />
                                    <ErrorMessage name="cf" component={FormFeedback} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                            <FormGroup className="mb-3">
                                    <Label htmlFor="cell">Telefono</Label>
                                    <Field
                                        name="cell"
                                        placeholder="cell"
                                        type="text"
                                        className={`form-control ${touched.cell && errors.cell ? 'is-invalid' : ''}`}
                                        //style={{ textTransform: 'uppercase'}}
                                    />
                                    <ErrorMessage name="cell" component={FormFeedback} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="indirizzo">Indirizzo</Label>
                                    <Field
                                        name="indirizzo"
                                        placeholder="Indirizzo"
                                        type="text"
                                        className={`form-control ${touched.indirizzo && errors.indirizzo ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name="indirizzo" component={FormFeedback} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="citta">Città</Label>
                                    <Field
                                        name="citta"
                                        placeholder="Città"
                                        type="text"
                                        className={`form-control ${touched.citta && errors.citta ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name="citta" component={FormFeedback} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                        <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="provincia">Provincia</Label>
                                    <Field as="select" name="provincia" className={`form-control ${touched.provincia && errors.provincia ? 'is-invalid' : ''}`}>
                                        <option value="">Seleziona la provincia</option>
                                        <option value="AG">Agrigento</option>
                                        <option value="AL">Alessandria</option>
                                        <option value="AN">Ancona</option>
                                        <option value="AO">Aosta</option>
                                        <option value="AR">Arezzo</option>
                                        <option value="AP">Ascoli Piceno</option>
                                        <option value="AT">Asti</option>
                                        <option value="AV">Avellino</option>
                                        <option value="BA">Bari</option>
                                        <option value="BT">Barletta-Andria-Trani</option>
                                        <option value="BL">Belluno</option>
                                        <option value="BN">Benevento</option>
                                        <option value="BG">Bergamo</option>
                                        <option value="BI">Biella</option>
                                        <option value="BO">Bologna</option>
                                        <option value="BZ">Bolzano</option>
                                        <option value="BS">Brescia</option>
                                        <option value="BR">Brindisi</option>
                                        <option value="CA">Cagliari</option>
                                        <option value="CL">Caltanissetta</option>
                                        <option value="CB">Campobasso</option>
                                        <option value="CE">Caserta</option>
                                        <option value="CT">Catania</option>
                                        <option value="CZ">Catanzaro</option>
                                        <option value="CH">Chieti</option>
                                        <option value="CO">Como</option>
                                        <option value="CS">Cosenza</option>
                                        <option value="CR">Cremona</option>
                                        <option value="KR">Crotone</option>
                                        <option value="CN">Cuneo</option>
                                        <option value="EN">Enna</option>
                                        <option value="FM">Fermo</option>
                                        <option value="FE">Ferrara</option>
                                        <option value="FI">Firenze</option>
                                        <option value="FG">Foggia</option>
                                        <option value="FC">Forlì-Cesena</option>
                                        <option value="FR">Frosinone</option>
                                        <option value="GE">Genova</option>
                                        <option value="GO">Gorizia</option>
                                        <option value="GR">Grosseto</option>
                                        <option value="IM">Imperia</option>
                                        <option value="IS">Isernia</option>
                                        <option value="AQ">L'Aquila</option>
                                        <option value="SP">La Spezia</option>
                                        <option value="LT">Latina</option>
                                        <option value="LE">Lecce</option>
                                        <option value="LC">Lecco</option>
                                        <option value="LI">Livorno</option>
                                        <option value="LO">Lodi</option>
                                        <option value="LU">Lucca</option>
                                        <option value="MC">Macerata</option>
                                        <option value="MN">Mantova</option>
                                        <option value="MS">Massa-Carrara</option>
                                        <option value="MT">Matera</option>
                                        <option value="ME">Messina</option>
                                        <option value="MI">Milano</option>
                                        <option value="MO">Modena</option>
                                        <option value="MB">Monza e Brianza</option>
                                        <option value="NA">Napoli</option>
                                        <option value="NO">Novara</option>
                                        <option value="NU">Nuoro</option>
                                        <option value="OR">Oristano</option>
                                        <option value="PD">Padova</option>
                                        <option value="PA">Palermo</option>
                                        <option value="PR">Parma</option>
                                        <option value="PV">Pavia</option>
                                        <option value="PG">Perugia</option>
                                        <option value="PU">Pesaro e Urbino</option>
                                        <option value="PE">Pescara</option>
                                        <option value="PC">Piacenza</option>
                                        <option value="PI">Pisa</option>
                                        <option value="PT">Pistoia</option>
                                        <option value="PN">Pordenone</option>
                                        <option value="PZ">Potenza</option>
                                        <option value="PO">Prato</option>
                                        <option value="RG">Ragusa</option>
                                        <option value="RA">Ravenna</option>
                                        <option value="RC">Reggio Calabria</option>
                                        <option value="RE">Reggio Emilia</option>
                                        <option value="RI">Rieti</option>
                                        <option value="RN">Rimini</option>
                                        <option value="RM">Roma</option>
                                        <option value="RO">Rovigo</option>
                                        <option value="SA">Salerno</option>
                                        <option value="SS">Sassari</option>
                                        <option value="SV">Savona</option>
                                        <option value="SI">Siena</option>
                                        <option value="SR">Siracusa</option>
                                        <option value="SO">Sondrio</option>
                                        <option value="SU">Sud Sardegna</option>
                                        <option value="TA">Taranto</option>
                                        <option value="TE">Teramo</option>
                                        <option value="TR">Terni</option>
                                        <option value="TO">Torino</option>
                                        <option value="TP">Trapani</option>
                                        <option value="TN">Trento</option>
                                        <option value="TV">Treviso</option>
                                        <option value="TS">Trieste</option>
                                        <option value="UD">Udine</option>
                                        <option value="VA">Varese</option>
                                        <option value="VE">Venezia</option>
                                        <option value="VB">Verbano-Cusio-Ossola</option>
                                        <option value="VC">Vercelli</option>
                                        <option value="VR">Verona</option>
                                        <option value="VV">Vibo Valentia</option>
                                        <option value="VI">Vicenza</option>
                                        <option value="VT">Viterbo</option>
                                    </Field>
                                    <ErrorMessage name="provincia" component={FormFeedback} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="cap">Codice Postale</Label>
                                    <Field
                                        name="cap"
                                        placeholder="Codice Postale"
                                        type="text"
                                        className={`form-control ${touched.cap && errors.cap ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name="cap" component={FormFeedback} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" type="submit" disabled={isSubmitting}>
                          Aggiorna i dati
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
