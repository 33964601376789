
import PropTypes from "prop-types";
import React from "react";
import {
  Container, Row, Col, Button, Card, CardBody, CardTitle, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

// import Tabella Magazzino
import TableMagazzino from "pages/Tables/TableMagazzino";

const Magazzino = props => {
    //meta title
    document.title = "Magazzino | UDI";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={props.t("Magazzino")} breadcrumbItem={props.t("UDI")}/>
            
                        {/* START  ------------  content*/}  
                        <Row>
                                <Col xl="12">
                                <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Magazzino</CardTitle>
                                    <TableMagazzino />
                                </CardBody>
                                </Card>
                                </Col>
                        </Row> 
                </Container>
            </div>
        </React.Fragment>
        
    )
}

export default withTranslation()(Magazzino);