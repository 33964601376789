import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Container, FormFeedback, Label, FormGroup, Button } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
//import { useFormik } from "formik";
import { Formik, Form, Field, ErrorMessage } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";


//AX
import UtenteUpdateForm from "../../ui-components/UtenteUpdateForm"; //form aws
import * as mutations from "graphql/mutations";
import { generateClient } from 'aws-amplify/api';
import * as queries from '../../graphql/queries';




const UserSettings = () => {


  //meta title
  document.title = "Profile | MedStock";
  const handleUpdate = async (values) => {
  };

  return (
    <React.Fragment>
        <div className="page-content">
         <Container fluid>
                {/* Render Breadcrumb */}
                <Breadcrumbs title="Utente" breadcrumbItem="Impostazioni" />

      </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserSettings);