import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import profile from "assets/images/iabaduu/logoMSorizz-black.png";
import logo from "../../assets/images/logo-MedStock-full.jpg";
import iabaico from "../../assets/images/iabaduu/iabaduu-ico250.jpg";
// const stripePromise = loadStripe('pk_test_hChrnky3hKMtMVIJEFz9ee3c');

const Success = () => {
  // State to hold session ID and line items
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const [lineItems, setLineItems] = useState([]);
  /*
  useEffect(() => {
    const fetchLineItems = async () => {
      if (sessionId) {
        const stripe = await stripePromise;
        const { data: { object: { data: lineItems } } } = await stripe.checkout.sessions.listLineItems(sessionId);
        setLineItems(lineItems);
      }
    };
    fetchLineItems();
  }, [sessionId]);
*/
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Complimenti !</h5>
                        <p>Registrazione e pagamento avvenuti correttamente.</p>
                        <p>Benvenuto in MedStock</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <p> &nbsp;</p>
                    <h3>Cosa devi fare per accedere:</h3>
                    <p> &nbsp;</p>
                    <ul>
                      <li>ti è stata inviata un email all'indirizzo indicato con le istruzioni</li>
                      <li>vai al link indicato per inserire la tua nuova password </li>
                      <li>accedi a <a href="https://medstock.it">MedStock.it</a> cliccando su Log-in in alto a destra</li>
                      <li>per qualsiasi problema puoi scrivere a <a href="mailto:supporto@medstock.it">supporto@medstock.it</a></li>
                    </ul>
                    <p> Buon lavoro dallo staff MedStock</p>
                  </div>
                
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                      <small> MedStock è un servizio di <a href="https://iabaduu.com">IABADUU srl</a>
                      <br />IT06511320969 via della Moscova, 40 – 20121 Milano
                      <br />info@iabaduu.com +39.02.40709003
                      <br/> © {new Date().getFullYear()} iabaduu srl</small>
                  </div>
                 
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Success;
