import { isArray } from "lodash";

import { getMagazzino, unloadItem, getProdotti } from './query_helper';

async function loadNomiProdotti(){
  return getProdotti();
}


async function loadMagazzino() {
  let ret = []
  if(localStorage.getItem("authUser")){
    console.log("Load magazzino");
     ret = await getMagazzino();
  } else {
    console.log("No auth user");
  }
  return ret;
}

function findNomeByGTIN(gtin, nomiProdotti) {
  if (!gtin) return 'GTIN not provided'; // Check if GTIN is null or undefined
  const items = nomiProdotti || [];
  const product = items.find(item => item.GTIN == gtin); // Ensure exact match
  return product ? product.Nome : '---';
}

function filterItemsWithNullDataScarico(arr, nomiProdotti) {
  var ret = []
  if(isArray(arr)){
      // Filter the array to include only those items where DataScarico is null
      const filteredMagazzino = arr.filter(item => !item.DataScarico);
      ret = formatMagazzino(filteredMagazzino, nomiProdotti);
  }
  return ret;
}

function formatMagazzino(magazzino, nomiProdotti) {
  var newMagazzino = [];
  if(isArray(magazzino) && isArray(nomiProdotti)){
      newMagazzino = JSON.parse(JSON.stringify(magazzino));
      newMagazzino.forEach(function (item) {
          /*
          const udi = item.UDI;
          const regexGTIN = /(?<=\)01=)(.{14}?)/gm;
          const match = udi.match(regexGTIN);
          const extractedGTIN = match ? match[0] : null; // Get the first matched GTIN, if any
          */
          const extractedGTIN = item.GTIN;
          const productName = extractedGTIN ? findNomeByGTIN(extractedGTIN, nomiProdotti) : 'GTIN not extracted';
          item.Nome = productName
      });
  }
  return newMagazzino;
}

async function unloadItemFromMagazzino(id){
  let ret = {};
  try {
      ret = await unloadItem(id);
  } catch (error) {
      throw error
  }
  return ret;
}

function findIdByUDI(arr, udi) {
  // Find the element in the array where the UDI matches the provided UDI
  const item = arr.find(element => element.UDI == udi);
  // If the item exists, return its id; otherwise, return null
  return item ? item.id : null;
}

function filterItemsInScadenza(arr, nomiProdotti) {
   // Filter the array to check expiring product in 2 months
  const today = new Date();
  // Create a new date instance for manipulation
  const futureDate = new Date(today);
  // Add two months to the date
  futureDate.setMonth(futureDate.getMonth() + 2);
  const dateTwoMonthsFromToday = futureDate.toISOString().split('T')[0]; // Formats the date to YYYY-MM-DD
  var ret = []
  if(Array.isArray(arr)){
      // Filter the array to include only those items where DataScarico is null
      const filteredMagazzino = arr.filter(item => item.Scadenza < dateTwoMonthsFromToday && !('DataScarico' in item));
      ret = formatMagazzino(filteredMagazzino, nomiProdotti);
  }
  return ret;
}

export { loadMagazzino, loadNomiProdotti, formatMagazzino, 
         filterItemsWithNullDataScarico, unloadItemFromMagazzino, findIdByUDI, filterItemsInScadenza };
