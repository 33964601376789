import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>MedStock&reg; {new Date().getFullYear()} © <a href="https://iabaduu.com" target="_blank" rel="noreferrer">iabaduu</a> srl.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
              <a href="https://iabaduu.com/wp-content/uploads/2024/05/MedStock-trattamento-dati.pdf" target="_blank" rel="noreferrer">privacy</a> - 
               <a href="https://iabaduu.com/wp-content/uploads/2024/05/MedStock-condizioni-di-fornitura.pdf" target="_blank" rel="noreferrer">condizioni</a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
