import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Container, Row, Col, Card, CardBody, CardTitle, CardHeader, CardText,
} from "reactstrap";
import {Link} from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

// Import apexChart
import BarChart from "../Charts/barchart";

// import Tabella Scarico
import TableScadenze from "pages/Tables/TableMagazzinoInScadenza";

function Dashboard() {
  // State to track if localStorage is ready
  const [localStorageReady, setLocalStorageReady] = useState(false);

  useEffect(() => {
    // Assume you have some setup function to prepare localStorage
    const prepareLocalStorage = async () => {
      // Your logic to prepare localStorage goes here
      // For example: await loadLocalStorageData();

      // Indicate that localStorage is ready
      setLocalStorageReady(true);
    };

    prepareLocalStorage();
  }, []); // Empty dependency array means this runs once on component mount

  // Meta title
  document.title = "Dashboard | MedStock";
    
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* START  ------------  content*/}
          <Row>
            <Col lg={12}>
              <Card outline color="primary" className="border">
                <CardHeader className="bg-transparent">
                  <h5 className="my-0 text-primary">
                    <i className="mdi mdi-bullseye-arrow me-3" />Magazzino digitale codici UDI
                  </h5>
                </CardHeader>
                <CardBody>
                  <CardTitle className="mt-0">Ricorda:</CardTitle>
                  <CardText>
                    Il 'Decreto 11 maggio 2023: identificazione, tracciabilità e nomenclatura dei dispositivi medici', dispone l'<b>obbligo di registrazione e conservazione SOLO dei prodotti con <Link to="/infoudi" >codice UDI</Link> presente</b>.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>  
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Giacenze UDI </CardTitle>
                  {/* Conditionally render BarChart if localStorage is ready */}
                  {localStorageReady && <BarChart dataColors='["--bs-success"]'/>}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Prodotti in scadenza nei prossimi 60 giorni</CardTitle>
                  <TableScadenze/>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* END    ------------ content*/} 
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Dashboard);
