// src/components/filter.
import React, { useMemo, useContext } from "react";
import { ToastContainer } from "react-toastify";
import PropTypes from 'prop-types';
import { ScaricoToastNotify, ScaricoToastErrore } from "helpers/toast_helper";

//import components
import TableContainer from '../../components/Common/TableContainer';

import { filterItemsInScadenza, unloadItemFromMagazzino, loadMagazzino } from "helpers/magazzino_helper";

import { GlobalDataContext } from "../../contexts/GlobalDataContext";
//toast


const TableScadenze = () => {
    const {magazzino,setMagazzino, nomiProdotti } = useContext(GlobalDataContext);

    //scarico prodotto (aggiungo data scarico)
    async function handleDelete(id) {
        try {
            const data = await unloadItemFromMagazzino(id);
            ScaricoToastNotify(data.DataScarico, data.DataCarico, data.GTIN, data.Lotto, data.Scadenza, data.Seriale); //send to toast
            const newData = await loadMagazzino();
            setMagazzino(newData);
        } catch (error) {
            console.log(error);
            ScaricoToastErrore(error.message);
        }
    };

    // useMemo for columns and the rest of the component follows...
       const columns = useMemo(
        () => [
            {
                header: 'UDI',
                accessorKey: 'UDI',
                show: false,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Prodotto',
                accessorKey: 'Nome',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Data Carico',
                accessorKey: 'DataCarico',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Codice Prodotto',
                accessorKey: 'GTIN',
                enableColumnFilter: false,
                enableSorting: true,
                
            },
            
            {
                header: 'Data Scadenza',
                accessorKey: 'Scadenza',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Lotto',
                accessorKey: 'Lotto',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Seriale',
                accessorKey: 'Seriale',
                enableColumnFilter: false,
                enableSorting: false,
            },
           
        ],
        []
    );

    //meta title
    document.title = "TableScadenze";

    return (
            <div className="container-fluid">
                <TableContainer
                    columns={columns}
                    data={filterItemsInScadenza(magazzino, nomiProdotti) || []}
                    isGlobalFilter={false}
                    isPagination={true}
                    SearchPlaceholder="cerca su qualsiasi campo..."
                    pagination="pagination"
                    paginationWrapper='dataTables_paginate paging_simple_numbers'
                    tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"             
                />
            </div>
    );
 }
 TableScadenze.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};

export default TableScadenze;