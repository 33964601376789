import withRouter from "components/Common/withRouter";
import React, { useEffect } from 'react';
import { Row, Col, CardBody, Card, Container, FormFeedback, Label, FormGroup, Button } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import axios from 'axios';
import logo from "../../assets/images/logo-MedStock-full.jpg";
import iabaico from "../../assets/images/iabaduu/iabaduu-ico250.jpg";
import Condizioni from '../../assets/pdf/MedStock-condizioni_di_fornitura.pdf';
import Trattamento from '../../assets/pdf/MedStock-trattamento_dati.pdf';

const Sottoscrizione = (props) => {
    // Meta title
    useEffect(() => {
        document.title = "Sottoscrizione MedStock";
    }, []);

    // Checkout data to backend
    const handleCheckout = async (values) => {
        try {
            console.log('Submitting form with values:', values); // Log form values
    
            const response = await axios.post(
                'https://' + process.env.REACT_APP_APIGATEWAY_ID + '.execute-api.us-east-1.amazonaws.com/' + process.env.REACT_APP_ENV + '/checkout',
                values

            );
    
            if (response.data && response.data.url) {
                window.location.href = response.data.url;
              } else {
                setMessage('An error occurred during the checkout process.');
              }
            } catch (error) {
              console.error('Error during checkout:', error);
          
              if (error.response) {
                // The request was made, but the server responded with a status code that falls out of the range of 2xx
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
                setMessage(`An error occurred: ${error.response.data.message || 'Unknown error'}`);
              } else if (error.request) {
                // The request was made, but no response was received
                console.error('Request data:', error.request);
                setMessage('No response was received from the server. Please try again later.');
              } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
                setMessage(`An error occurred: ${error.message}`);
              }
            }
          };

    return (
        <React.Fragment>
            {/* <div className="account-pages my-5 pt-sm-5">*/}
            <section className="section bg-white" id="sottoscrizione">
                <Container>
                    <Row>
                        <Col lg="12">
                        <div className="text-center mb-5">
                            <div className="small-title">Acquista online il servizio</div>
                            <h4>Sottoscrivi MedStock</h4>
                        </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                    <Col lg={6}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col className="col-7">
                                        <div className="text-primary p-4">
                                            <h5 className="text-primary">Prova MedStock</h5>
                                            <h2>10 giorni gratis</h2>
                                            <p>poi €280.00 + IVA all'anno</p>
                                            <p>Canone annuale servizio gestione magazzino UDI</p>
                                           
                                        </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                        <img src={logo} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                        </Card>
                        <div className="mt-5">
                            <p>Il servizio é riservato ai soli professionisti dotati di Partita IVA.</p>
                            <ol>
                                <li>Cliccando sul pulsante verrai reindirizzato al sistema di pagamento sicuro</li>
                                <li>Una volta conclusa con successo la transazione riceverai via email le credenziali di accesso al sistema.</li>
                                <li>Potrai iniziare ad usare da subito MedStock</li>
                            </ol>
                            <p></p>
                            <ul>
                                <li>Puoi sempre annullare l'abbonamento durante il periodo di prova, senza alcun addebito, a <a href="https://billing.stripe.com/p/login/4gwcPk0099ASc6cbII">questo link</a></li>
                                <li>Per i centri medici organizzati come società vai <a href="/sottoscrizione-corporate">qui</a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={6}>      
                                <Card className="overflow-hidden">      
                                <CardBody className="pt-0">
                                    <Formik
                                        initialValues={{
                                            codice_sdi: '',
                                            nome: '',
                                            cognome: '',
                                            fullname: '', // Add this line
                                            piva: 'IT',
                                            cf: '',
                                            cell: '',
                                            line1: '',
                                            city: '',
                                            state: 'IT',
                                            postal_code: '',
                                            country: 'IT',
                                            email: '',
                                            emailcheck: '',
                                            terms: false,
                                            terms2: false,
                                            provincia: '',
                                        }}
                                        validationSchema={Yup.object({
                                            codice_sdi: Yup.string()
                                                .required("Obbligatorio")
                                                .max(7, "Deve essere di 7 caratteri")
                                                .min(7, "Deve essere di 7 caratteri"),
                                            nome: Yup.string().required("Obbligatorio"),
                                            cognome: Yup.string().required("Obbligatorio"),
                                            piva: Yup.string()
                                                .required("Obbligatorio")
                                                .max(13, "IT + numero piva")
                                                .matches(
                                                    /(IT)([0-9]{11})/,
                                                    "piva in formato europeo"
                                                  ),
                                            cf: Yup.string()
                                                .required("Obbligatorio")
                                                .matches(
                                                    /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/gm,
                                                    "errore nel codice fiscale"
                                                  ),     
                                            cell: Yup.string()
                                                .required("Obbligatorio")
                                                .matches(
                                                    /^\+[1-9]\d{1,14}$/,
                                                    "Errore nel numero, deve iniziare con +39 e contenere solo numeri"
                                                ),       
                                            line1: Yup.string().required("Obbligatorio"),
                                            city: Yup.string().required("Obbligatorio"),
                                            postal_code: Yup.string()
                                                .required("Obbligatorio")
                                                .min(5, "Deve essere di 5 caratteri")
                                                .max(5, "Deve essere di 5 caratteri")
                                                .matches(
                                                    /^[#0-9]+$/,
                                                    "solo numerico",
                                                ),    
                                            //country: Yup.string().required("Obbligatorio"),
                                            provincia: Yup.string().required("Obbligatorio"),
                                            email: Yup.string()
                                                .email("Must be a valid Email")
                                                .max(255)
                                                .required("Email is required"),
                                            emailcheck: Yup.string()
                                                .oneOf([Yup.ref('email'), null], "Emails must match")
                                                .required("Please confirm your email"),    
                                            terms: Yup.bool().oneOf([true], "Devi accettare le condizioni"),
                                            terms2: Yup.bool().oneOf([true], "Devi accettare le condizioni"),
                                        })}
                                        onSubmit={handleCheckout}
                                    >
                                        {({ handleSubmit, isSubmitting, errors, touched, values, setFieldValue }) => {
                                            useEffect(() => {
                                                setFieldValue('fullname', `${values.nome} ${values.cognome}`);
                                            }, [values.nome, values.cognome, setFieldValue]);

                                            return (
                                                <Form onSubmit={handleSubmit}>
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="nome">Nome</Label>
                                                                <Field
                                                                    name="nome"
                                                                    placeholder="Nome"
                                                                    type="text"
                                                                    className={`form-control ${touched.nome && errors.nome ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage name="nome" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cognome">Cognome</Label>
                                                                <Field
                                                                    name="cognome"
                                                                    placeholder="Cognome"
                                                                    type="text"
                                                                    className={`form-control ${touched.cognome && errors.cognome ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage name="cognome" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Field name="fullname" type="hidden" />
                                                    <Field name="country" type="hidden" />
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="codice_sdi">Codice SDI</Label>
                                                                <Field
                                                                    name="codice_sdi"
                                                                    placeholder="Codice SDI"
                                                                    type="text"
                                                                    className={`form-control ${touched.codice_sdi && errors.codice_sdi ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage name="codice_sdi" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="piva">Partita IVA</Label>
                                                                <Field
                                                                    name="piva"
                                                                    placeholder="formato europeo es. IT02345678910"
                                                                    type="text"
                                                                    className={`form-control ${touched.piva && errors.piva ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage name="piva" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cf">Codice Fiscale</Label>
                                                                <Field
                                                                    name="cf"
                                                                    placeholder="CF tutto maiuscolo"
                                                                    type="text"
                                                                    className={`form-control ${touched.cf && errors.cf ? 'is-invalid' : ''}`}
                                                                    //style={{ textTransform: 'uppercase'}}
                                                                />
                                                                <ErrorMessage name="cf" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                        <FormGroup className="mb-3">
                                                                <Label htmlFor="cell">Telefono</Label>
                                                                <Field
                                                                    name="cell"
                                                                    placeholder="cell"
                                                                    type="text"
                                                                    className={`form-control ${touched.cell && errors.cell ? 'is-invalid' : ''}`}
                                                                    //style={{ textTransform: 'uppercase'}}
                                                                />
                                                                <ErrorMessage name="cell" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="line1">Indirizzo</Label>
                                                                <Field
                                                                    name="line1"
                                                                    placeholder="Indirizzo"
                                                                    type="text"
                                                                    className={`form-control ${touched.line1 && errors.line1 ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage name="line1" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="city">Città</Label>
                                                                <Field
                                                                    name="city"
                                                                    placeholder="Città"
                                                                    type="text"
                                                                    className={`form-control ${touched.city && errors.city ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage name="city" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                   
                                                    <Row>
                                                         {/*
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="country">Stato</Label>
                                                                <Field
                                                                    name="country"
                                                                    placeholder="Stato"
                                                                    type="text"
                                                                    className={`form-control ${touched.country && errors.country ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage name="country" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                        */}
                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="provincia">Provincia</Label>
                                                                <Field as="select" name="provincia" className={`form-control ${touched.provincia && errors.provincia ? 'is-invalid' : ''}`}>
                                                                    <option value="">Seleziona la provincia</option>
                                                                    <option value="AG">Agrigento</option>
                                                                    <option value="AL">Alessandria</option>
                                                                    <option value="AN">Ancona</option>
                                                                    <option value="AO">Aosta</option>
                                                                    <option value="AR">Arezzo</option>
                                                                    <option value="AP">Ascoli Piceno</option>
                                                                    <option value="AT">Asti</option>
                                                                    <option value="AV">Avellino</option>
                                                                    <option value="BA">Bari</option>
                                                                    <option value="BT">Barletta-Andria-Trani</option>
                                                                    <option value="BL">Belluno</option>
                                                                    <option value="BN">Benevento</option>
                                                                    <option value="BG">Bergamo</option>
                                                                    <option value="BI">Biella</option>
                                                                    <option value="BO">Bologna</option>
                                                                    <option value="BZ">Bolzano</option>
                                                                    <option value="BS">Brescia</option>
                                                                    <option value="BR">Brindisi</option>
                                                                    <option value="CA">Cagliari</option>
                                                                    <option value="CL">Caltanissetta</option>
                                                                    <option value="CB">Campobasso</option>
                                                                    <option value="CE">Caserta</option>
                                                                    <option value="CT">Catania</option>
                                                                    <option value="CZ">Catanzaro</option>
                                                                    <option value="CH">Chieti</option>
                                                                    <option value="CO">Como</option>
                                                                    <option value="CS">Cosenza</option>
                                                                    <option value="CR">Cremona</option>
                                                                    <option value="KR">Crotone</option>
                                                                    <option value="CN">Cuneo</option>
                                                                    <option value="EN">Enna</option>
                                                                    <option value="FM">Fermo</option>
                                                                    <option value="FE">Ferrara</option>
                                                                    <option value="FI">Firenze</option>
                                                                    <option value="FG">Foggia</option>
                                                                    <option value="FC">Forlì-Cesena</option>
                                                                    <option value="FR">Frosinone</option>
                                                                    <option value="GE">Genova</option>
                                                                    <option value="GO">Gorizia</option>
                                                                    <option value="GR">Grosseto</option>
                                                                    <option value="IM">Imperia</option>
                                                                    <option value="IS">Isernia</option>
                                                                    <option value="AQ">L'Aquila</option>
                                                                    <option value="SP">La Spezia</option>
                                                                    <option value="LT">Latina</option>
                                                                    <option value="LE">Lecce</option>
                                                                    <option value="LC">Lecco</option>
                                                                    <option value="LI">Livorno</option>
                                                                    <option value="LO">Lodi</option>
                                                                    <option value="LU">Lucca</option>
                                                                    <option value="MC">Macerata</option>
                                                                    <option value="MN">Mantova</option>
                                                                    <option value="MS">Massa-Carrara</option>
                                                                    <option value="MT">Matera</option>
                                                                    <option value="ME">Messina</option>
                                                                    <option value="MI">Milano</option>
                                                                    <option value="MO">Modena</option>
                                                                    <option value="MB">Monza e Brianza</option>
                                                                    <option value="NA">Napoli</option>
                                                                    <option value="NO">Novara</option>
                                                                    <option value="NU">Nuoro</option>
                                                                    <option value="OR">Oristano</option>
                                                                    <option value="PD">Padova</option>
                                                                    <option value="PA">Palermo</option>
                                                                    <option value="PR">Parma</option>
                                                                    <option value="PV">Pavia</option>
                                                                    <option value="PG">Perugia</option>
                                                                    <option value="PU">Pesaro e Urbino</option>
                                                                    <option value="PE">Pescara</option>
                                                                    <option value="PC">Piacenza</option>
                                                                    <option value="PI">Pisa</option>
                                                                    <option value="PT">Pistoia</option>
                                                                    <option value="PN">Pordenone</option>
                                                                    <option value="PZ">Potenza</option>
                                                                    <option value="PO">Prato</option>
                                                                    <option value="RG">Ragusa</option>
                                                                    <option value="RA">Ravenna</option>
                                                                    <option value="RC">Reggio Calabria</option>
                                                                    <option value="RE">Reggio Emilia</option>
                                                                    <option value="RI">Rieti</option>
                                                                    <option value="RN">Rimini</option>
                                                                    <option value="RM">Roma</option>
                                                                    <option value="RO">Rovigo</option>
                                                                    <option value="SA">Salerno</option>
                                                                    <option value="SS">Sassari</option>
                                                                    <option value="SV">Savona</option>
                                                                    <option value="SI">Siena</option>
                                                                    <option value="SR">Siracusa</option>
                                                                    <option value="SO">Sondrio</option>
                                                                    <option value="SU">Sud Sardegna</option>
                                                                    <option value="TA">Taranto</option>
                                                                    <option value="TE">Teramo</option>
                                                                    <option value="TR">Terni</option>
                                                                    <option value="TO">Torino</option>
                                                                    <option value="TP">Trapani</option>
                                                                    <option value="TN">Trento</option>
                                                                    <option value="TV">Treviso</option>
                                                                    <option value="TS">Trieste</option>
                                                                    <option value="UD">Udine</option>
                                                                    <option value="VA">Varese</option>
                                                                    <option value="VE">Venezia</option>
                                                                    <option value="VB">Verbano-Cusio-Ossola</option>
                                                                    <option value="VC">Vercelli</option>
                                                                    <option value="VR">Verona</option>
                                                                    <option value="VV">Vibo Valentia</option>
                                                                    <option value="VI">Vicenza</option>
                                                                    <option value="VT">Viterbo</option>
                                                                </Field>
                                                                <ErrorMessage name="provincia" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="6">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="postal_code">Codice Postale</Label>
                                                                <Field
                                                                    name="postal_code"
                                                                    placeholder="Codice Postale"
                                                                    type="text"
                                                                    className={`form-control ${touched.postal_code && errors.postal_code ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage name="postal_code" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="email">Email</Label>
                                                                <Field
                                                                    name="email"
                                                                    placeholder="Email"
                                                                    type="email"
                                                                    className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage name="email" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="email">Verifica Email</Label>
                                                                <Field
                                                                    name="emailcheck"
                                                                    placeholder="Verifica Email"
                                                                    type="email"
                                                                    className={`form-control ${touched.emailcheck && errors.emailcheck ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage name="emailcheck" component={FormFeedback} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <div className="form-check">
                                                                    <Field
                                                                        type="checkbox"
                                                                        className={`form-check-input ${touched.terms && errors.terms ? 'is-invalid' : ''}`}
                                                                        id="invalidCheck"
                                                                        name="terms"
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        htmlFor="invalidCheck"
                                                                    >
                                                                        {" "}
                                                                        Accetto e sottoscrivo le <a href="https://iabaduu.com/wp-content/uploads/2024/05/MedStock-condizioni-di-fornitura.pdf" target="_blank" rel="noreferrer">Condizioni di fornitura dei servizi</a>
                                                                    </Label>
                                                                    <ErrorMessage name="terms" component={FormFeedback} />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <div className="form-check">
                                                                    <Field
                                                                        type="checkbox"
                                                                        className={`form-check-input ${touched.terms2 && errors.terms2 ? 'is-invalid' : ''}`}
                                                                        id="invalidCheck2"
                                                                        name="terms2"
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        htmlFor="invalidCheck2"
                                                                    >
                                                                        {" "}
                                                                        Accetto e sottoscrivo le <a href="https://iabaduu.com/wp-content/uploads/2024/05/MedStock-trattamento-dati.pdf" target="_blank" rel="noreferrer">policy del trattamento dei dati</a>
                                                                    </Label>
                                                                    <ErrorMessage name="terms2" component={FormFeedback} />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Button color="primary" type="submit" disabled={isSubmitting}>
                                                        Sottoscrivi
                                                    </Button>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </CardBody>
                            </Card>
                            {/*
                            <div className="mt-5">
                                <small>MedStock è un servizio di <a href="https://iabaduu.com">IABADUU srl</a>
                                    <br />IT06511320969 via della Moscova, 40 – 20121 Milano
                                    <br />info@iabaduu.com +39.02.40709003
                                    <br /> © {new Date().getFullYear()} iabaduu srl
                                </small>
                            </div>
                            */}
                        </Col>
                    </Row>
                </Container>
            {/*</div>*/}
            </section>
        </React.Fragment>
    );
};

export default withRouter(Sottoscrizione);
