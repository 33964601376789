
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import {
  Container, Row, Col, Form, FormGroup, Label, Button, Card, CardBody, CardTitle, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Collapse, Nav, NavItem, NavLink,TabContent, TabPane, CardText,
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import Trattamentoviso from "./trattamentoviso";
import Gallery from "./gallery";







const Trattamento = props => {
    //leggo id che arriva da pazienti list
    const location = useLocation();
    const { idpaziente } = location.state || {}; // get the id from state

    //meta title
    document.title = "Trattamento";

    //elenco trattamenti
const [selectedGroup, setselectedGroup] = useState(null);
const optionGroup2 = [
    {
      label: "TRATTAMENTI",
      options: [
        { label: "Tossina Botulinica", value: "1" },
        { label: "Filler", value: "11" },
        { label: "Rivitalizzazione Cutanea", value: "111" },
        { label: "Fili Trazione", value: "1111" },
        { label: "Fili Biostimolanti", value: "111ss" },
      ]
    },
    {
      label: "TERAPIE",
      options: [
        { label: "Intradermoterapia", value: "11dd1" },
        { label: "Carbossiterapia", value: "1d1dd1" },
      ]
    },
    {
        label: "LASER",
        options: [
          { label: "Laser Vascolare", value: "2" },
          { label: "Laser Frazionato", value: "22" },
          { label: "Laser Q-Switch", value: "222" },
          { label: "Laser Epilazione", value: "2222" }
        ]
      }
  ];


  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  //nav delle parti del corpo
    const [activeTab1, setactiveTab1] = useState("1");
    const toggle1 = tab => {
        if (activeTab1 !== tab) {
          setactiveTab1(tab);
        }
      };



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={props.t("Pazienti")} breadcrumbItem={props.t("Prestazione")}/>
            
                        {/* START  ------------  content*/}  
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Paziente</CardTitle>
                                        {idpaziente ? <p>ID: {idpaziente}</p> : <p>No ID provided</p>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Trattamento</CardTitle>
                                        <div className="mb-3">
                                        <Label>Seleziona il trattamento</Label>
                                        <Select
                                            value={selectedGroup}
                                            onChange={() => {
                                            handleSelectGroup();
                                            }}
                                            options={optionGroup2}
                                            className="select2-selection"
                                        />
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                                    
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Area</CardTitle>
                                        <div>
                                        <Nav pills className="navtab-bg nav-justified">
                                            <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "1", })} onClick={() => { toggle1("1"); }}>
                                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                <span className="d-none d-sm-block">Viso</span>
                                            </NavLink>
                                            </NavItem>
                                            <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "2", })} onClick={() => { toggle1("2"); }}>
                                                <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                <span className="d-none d-sm-block">Collo</span>
                                            </NavLink>
                                            </NavItem>
                                            <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "3", })} onClick={() => { toggle1("3"); }}>
                                                <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                                <span className="d-none d-sm-block">Décolleté</span>
                                            </NavLink>
                                            </NavItem>
                                            <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "4", })} onClick={() => { toggle1("4"); }}>
                                                <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                                                <span className="d-none d-sm-block">Braccia</span>
                                            </NavLink>
                                            </NavItem>
                                            <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "5", })} onClick={() => { toggle1("5"); }}>
                                                <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                                                <span className="d-none d-sm-block">Addome</span>
                                            </NavLink>
                                            </NavItem>
                                            <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "6", })} onClick={() => { toggle1("6"); }}>
                                                <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                                                <span className="d-none d-sm-block">Glutei</span>
                                            </NavLink>
                                            </NavItem>
                                            <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "7", })} onClick={() => { toggle1("7"); }}>
                                                <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                                                <span className="d-none d-sm-block">Gambe</span>
                                            </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                          <Trattamentoviso/>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            value="collo"
                            id="collo"
                             />
                            <label
                            className="form-check-label"
                            htmlFor="collo"
                          > Collo
                          </label>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Etsy mixtape wayfarers, ethical wes anderson tofu
                            before they sold out mcsweeney&apos;s organic lomo retro
                           

                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                           
                            sweater. Lomo wolf viral, mustache readymade
                            thundercats keffiyeh craft beer marfa ethical. Wolf
                            salvia freegan, sartorial keffiyeh echo park vegan.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                           
                            sweater. Lomo wolf viral, mustache readymade
                            thundercats keffiyeh craft beer marfa ethical. Wolf
                            salvia freegan, sartorial keffiyeh echo park vegan.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                           
                            sweater. Lomo wolf viral, mustache readymade
                            thundercats keffiyeh craft beer marfa ethical. Wolf
                            salvia freegan, sartorial keffiyeh echo park vegan.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="7">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                           
                            sweater. Lomo wolf viral, mustache readymade
                            thundercats keffiyeh craft beer marfa ethical. Wolf
                            salvia freegan, sartorial keffiyeh echo park vegan.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Consenso Informato</CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>    
                        <Row>
                                <Col xl="12">
                                <Card>
                                <CardTitle>Gallery</CardTitle>
                                <CardBody>
                                           
                                </CardBody>
                                </Card>  
                                </Col>
                        </Row> 
                </Container>
            </div>
        </React.Fragment>
        
    )
}

export default withTranslation()(Trattamento);