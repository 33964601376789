import React from "react"
import { Row, Col } from "reactstrap"

//Import Images
//import logolight from "../../../assets/images/logo-light.png"
import logolight from "../../../assets/images/iabaduu/logoMSorizz-white.png";

const FooterLink = () => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="6">
          <div className="mb-4">
            <img src={logolight} alt="" height="20" />
          </div>

          <p className="mb-2">
            MedStock &reg; &nbsp;&nbsp;<a href="https://www.facebook.com/profile.php?id=61559588272285"><i className="bx bxl-facebook-circle"></i></a>&nbsp;<a href="https://www.instagram.com/medstock.it/"><i className="bx bxl-instagram-alt"></i></a>
          </p>
          <p>
            {new Date().getFullYear()}   © <a href="https://iabaduu.com">IABADUU srl</a> <br/>
            IT06511320969
            via della Moscova, 40 – 20121 Milano<br/>
            info@iabaduu.com     +39.02.40709003
          </p>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FooterLink
