import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

async function getHeaders() {
    // If needed, refresh the token id
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString();
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': idToken
    };
    return headers;
}
function getAPIUrl(method) {
    return 'https://' + process.env.REACT_APP_APIGATEWAY_ID + '.execute-api.us-east-1.amazonaws.com/' + process.env.REACT_APP_ENV + '/api/' + method;
}
async function getMagazzino() {
    const response = await axios.get(
    getAPIUrl('getMagazzino'),
    { headers: await getHeaders() }
    );
    return response.data;
}

async function loadItem(data) {
    const response = await axios.post(
    getAPIUrl('loadItem'),
    data,
    { headers: await getHeaders() }
    );
    return response;
}


async function unloadItem(id) {
    let currentDate = new Date().toJSON().slice(0, 10);
    const response = await axios.post(
    getAPIUrl('unloadItem'),
        { id: id, DataScarico: currentDate},
        { headers: await getHeaders() }
        );
    return response.data.Attributes;
}

async function getProdotti() {
    const response = await axios.get(
    getAPIUrl('listProdotti'),
    { headers: await getHeaders() }
    );
    return response.data;
}

async function getUserProfile() {
    const response = await axios.get(
    getAPIUrl('getUserProfile'),
    { headers: await getHeaders() }
    );
    return response.data
}

async function updateUserProfile(data) {
    const response = await axios.post(
    getAPIUrl('updateUserProfile'),
    data,
    { headers: await getHeaders() }
    );
    return response.data
}


export { getMagazzino, unloadItem, getProdotti, getUserProfile, loadItem, updateUserProfile };