import React from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// import images
import profile from "assets/images/iabaduu/logoMSorizz-black.png";
//import logo from "assets/images/logo.svg";

const Errore = props => {

  //meta title
  document.title = "Errore";

  

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Errore !</h5>
                        <p>Qualcosa è andato storto</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <p><br/></p>
                    <p>Ti preghiamo di contattarci all'indirizzo info@medstock.it</p>
                  </div>
                  <div className="p-2">
                 
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Torna alla pagina di ?{" "} <Link to="/" className="fw-medium text-primary">
                    {" "}
                    Login{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} iabaduu srl
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Errore);

