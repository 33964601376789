import PropTypes from 'prop-types'
import React from "react"
import { Link } from "react-router-dom"
import {Container, Row, Col, Card, CardBody} from "reactstrap"

// import images
import logo from "../../assets/images/logo-MedStock-full.jpg";

const Welcome = props => {
  return (
    <React.Fragment>
     
      <section className="my-5 pt-sm-5 ">
        <Container>
        <Row>
            <Col lg="12 text-center">
              <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />
                  4
                </h1>
                <h4 className="text-uppercase">PAGINA NON TROVATA</h4>
                <div className="mt-5 text-center">
                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to="/dashboard"
                  >
                    Torna alla home
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 text-center">
              <div className="home-wrapper bgwelcome">


                <Row className="justify-content-center">
                  <Col sm={6}>
                  <img className="me-2" width="600" src={logo} alt="logo"></img>
                   </Col>   
                   
                </Row>
                <h3 className="mt-5">Sistema di gestione magazzino UDI per il personale sanitario</h3>
                <p>Software web per il carico e scarico dei prodotti con codice UDI e conservazione in cloud del magazzino</p>

                <Row>
                  <Col md={4}>
                    <Card className="mt-4 maintenance-box">
                      <CardBody>
                        <i className="fas fa-hand-holding-medical mb-4 h1 text-primary"></i>
                        <h5 className="font-size-15 text-uppercase">A chi è rivolto?</h5>
                        <p className="text-muted mb-0">Dal 15 gennaio 2024 vige per i Medici di libera professione l’obbligo di registrare e conservare, in formato elettronico, per 15 anni, i dati relativi a:
                        <br/><br/>
                           codice UDI<br/>
                           data di acquisto<br/>
                           data di vendita/dispensazione all’utente finale  
                        <br/><br/>
                        dei Dispositivi Medici (DM) di Classe III e dei DM impiantabili di Classe III o IIb (riferimento di legge: Decreto 11 maggio 2023 del Ministro della Salute, GU n.166 del 18.7.2023)</p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="mt-4 maintenance-box">
                      <CardBody>
                        <i className="fas fa-barcode mb-4 h1 text-primary"></i>
                        <h5 className="font-size-15 text-uppercase">
                          Cos'è L'UDI?</h5>
                        <p className="text-muted mb-0">L’MDR (Medical Device Regulation - Regolamento (UE) 2017/745) ha introdotto il sistema di identificazione unica del dispositivo (Unique Device Identification,
UDI), una novità rilevante  sul versante della tracciabilità dei DM.</p>
<br/>
<p className="text-muted mb-0">
L’<strong>UDI</strong> è un codice numerico o alfanumerico in formato leggibile dall’uomo o in forma di un
codice a barre, un codice QR o un qualsiasi altro codice a lettura ottica.</p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="mt-4 maintenance-box">
                      <CardBody>
                        <i className="fas fa-cloud mb-4 h1 text-primary"></i>
                        <h5 className="font-size-15 text-uppercase">
                          cosa offre medstock?</h5>
                          <p className="text-muted mb-0">
                            MedStock è un sistema dedicato ai medici di libera professione, che sono tenuti a registrare conservare un magazzino "personale" dei dispositivi medici venduti o somministrati ai pazienti.
                        </p>
                        <br/>
                          <p className="text-muted mb-0">
                            MedStock è un applicazione in cloud finalizzata alla <strong>conservazione</strong> sicura dei tracciati di magazzino.
                        </p>
                        <br/>
                        <p className="text-muted mb-0">
                            Il sistema viene <strong>costantemente aggiornato</strong> in base alla normativa vigente.
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

Welcome.propTypes = {
  children: PropTypes.any
}

export default Welcome
