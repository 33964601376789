import React from "react"
import { Container, Row, Col, Card, CardBody, CardFooter, UncontrolledTooltip } from "reactstrap"

//Import Images
import { Link } from "react-router-dom";
import { icoLandingTeam } from "../../../common/data";
import logoiaba from "../../../assets/images/medstock-logo_scritta-vert.png";
import logo from "../../../assets/images/logo-MedStock-full.jpg";

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper/modules";
import "../../../../node_modules/swiper/swiper.scss";

//AWS
import {
  ContattoCreateForm 
 } from '../../../ui-components';
 import { Message, Flex } from '@aws-amplify/ui-react';
 import { useState } from 'react';
 

const OurTeam = () => {

  const [alertActive, setAlertActive] = useState(false);

  return (
    <React.Fragment>
      <section className="section" id="team">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Contattaci per avere maggiori informazioni</div>
                <h4>Contattaci</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card className="text-light card bg-dark">
              <img src={logo} alt="logo"></img>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
              <ContattoCreateForm
                    onSubmit={(fields) => {
                        // Example function to trim all string inputs
                        const updatedFields = {}
                        Object.keys(fields).forEach(key => {
                            if (typeof fields[key] === 'string') {
                                updatedFields[key] = fields[key].trim()
                            } else {
                                updatedFields[key] = fields[key]
                            }
                        })
                        return updatedFields
                    }}
                    onSuccess={() => setAlertActive(!alertActive)}
                />
                 {alertActive ? (
                      <Message role="Alert" colorTheme="info">Messaggio inviato</Message>
               ) : null}
              </Card>
            </Col>
          </Row>

        </Container>
      </section>
    </React.Fragment>
  )
}

export default OurTeam
