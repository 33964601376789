// src/components/filter.
import React, { useMemo, useContext } from "react";
import PropTypes from 'prop-types';

//import components
import TableContainer from '../../components/Common/TableContainer';
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import { formatMagazzino } from 'helpers/magazzino_helper'
  


const TableMagazzino = props => {
    const {magazzino, nomiProdotti} = useContext(GlobalDataContext);

    const columns = useMemo(
        () => [
            {
                header: 'UDI',
                accessorKey: 'UDI',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Prodotto',
                accessorKey: 'Nome',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Data Carico',
                accessorKey: 'DataCarico',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Codice Prodotto',
                accessorKey: 'GTIN',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Data Scadenza',
                accessorKey: 'Scadenza',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Lotto',
                accessorKey: 'Lotto',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Seriale',
                accessorKey: 'Seriale',
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: 'Data Scarico',
                accessorKey: 'DataScarico',
                enableColumnFilter: false,
                enableSorting: true,
            },
        ],
        []
    );

    //meta title
    document.title = "TableMagazzino";

    return (
        
            <div className="container-fluid">
                <TableContainer
                    columns={columns}
                    data={formatMagazzino(magazzino, nomiProdotti) || []}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="cerca qui..."
                    pagination="pagination"
                    paginationWrapper='dataTables_paginate paging_simple_numbers'
                    tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                />
            </div>
        
    );
}
TableMagazzino.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default TableMagazzino;