import React, { useState, useContext } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//images
import qrcode from "../../assets/images/iabaduu/qrcode.png";

//AWS
import { filterItemsWithNullDataScarico, unloadItemFromMagazzino, loadMagazzino, findIdByUDI } from "../../helpers/magazzino_helper";
import TableScarico from "pages/Tables/TableMagazzinoScarico";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
//toast
import { ScaricoToastNotify, ScaricoToastErrore } from "helpers/toast_helper";

const UnLoad = props => {
    const {magazzino,setMagazzino, nomiProdotti } = useContext(GlobalDataContext);

    async function updateMagazzino (magazzino, nomiProdotti){
        var udi = document.getElementById("UDI").value;
        console.log('UDI', udi);
        // filtro prodotti non scaricati
        const filteredArray = filterItemsWithNullDataScarico(magazzino, nomiProdotti);
        console.log(filteredArray)
        // cerco ID by UDI
        const id = findIdByUDI(filteredArray, udi);
        console.log(id);
        try {
            const data = await unloadItemFromMagazzino(id);
            ScaricoToastNotify(data.DataScarico, data.DataCarico, data.GTIN, data.Lotto, data.Scadenza, data.Seriale); //send to toast
            const newData = await loadMagazzino();
            setMagazzino(newData);
        } catch (error) {
            console.log(error);
            ScaricoToastErrore(error.message);
        } finally {
          document.getElementById("UDI").value = '';
        }
    }

    //reset udi full OK
    const [value, setValue] = useState("");
    const onInput = (e) => setValue(e.target.value);
    const onClear = () => {
    setValue("");
    };

    //meta title
    document.title = "UnLoad | UDI";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={props.t("UDI")} breadcrumbItem={props.t("Scarico prodotti")}/>
                        {/* START  ------------  content*/}
                        <Row>
                        <Col xs={12}>
                        <Card>
                            <CardBody>
                            <div className="d-flex align-items-center hstack gap-3">
                                <div className="flex-shrink-0 me-3">
                                    <img className="rounded me-2" width="200" src={qrcode} alt="qrcode"></img>
                                </div>
                                <div className="flex-grow-1">
                                    <h5>Scansiona qui il QRcode UDI del prodotto</h5>
                                    <p>
                                    <input autoFocus className="form-control" type="text" id="UDI"  onInput={onInput}></input>
                                    </p>
                                </div>
                               <div className="flex-shrink-0 mb-3">
                                    <button type="submit"
                                     className="btn btn-danger w-md bottom"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                    onClick={() => {
                                        updateMagazzino(magazzino, nomiProdotti);
                                        onClear();
                                      }}
                                    >Scarica</button>
                                </div>

                            </div>
                            </CardBody>
                        </Card>
                        </Col>
                        </Row>
                         {/* START  ------------  content*/} 
                        <Row>
                            <Col xl="12">
                            <Card>
                              <CardHeader>
                                RICERCA MANUALE DEL PRODOTTO
                              </CardHeader>
                              <CardBody>
                                  <TableScarico></TableScarico>
                              </CardBody>
                            </Card>
                            </Col>
                        </Row>
                        
                </Container>
            </div>
        </React.Fragment>
        
    )
}

export default withTranslation()(UnLoad);