//import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavLink,
    TabContent,
    TabPane,
  } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
/// TEST
import React from 'react';
import { StorageImage } from '@aws-amplify/ui-react-storage';

//images funzione per prenderele IMMAGINI !!!
//import immagine from "https://medstock-staging144819-staging.s3.us-east-1.amazonaws.com/public/uploads/b4c8d4f8-3001-702a-6943-c07237e25f6b/3378ab3a-ed09-4a31-9410-fe246d0dc7a5/2024-06-20/foto-magazzino.jpg?x-amz-content-sha256=e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAS5PQIXLKIHJVG55A%2F20240621%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240621T080318Z&X-Amz-SignedHeaders=host&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELD%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQCzO%2FM2rC6K2hzDDzSquKUkB8pwcQqRhN72%2ByrFRRipnAIhAI0r%2B9DvHbCbVu0iyg2KXZCzJh8MzCWjGvFlhmpWk3x6KsQECFkQABoMMjAwNzU2NzM4NzcyIgwWp%2F%2BQayeBkpPSIJwqoQQvzmXp4v4McwEjcJnoaii6lQfoYUmebpe8DPrKzszdDW1BUvAXM3sPxr%2F1zHY4ZTDsItkGkZQ%2BO3Q5OZ25IavlKp%2B3xfJGZ4gIGSghcniTZ1bigpxh0MFwLIV9OpGLT%2BdJAKrzEA%2FeiXR4ZRRjzpyUSjqri0Ti%2BfS3mKQAlkqTm3EhuWQt5rHSOmSj044GcixGzCkRQG67f1FxaFFijFTp0X%2FPtmTHK6dB3n1K3rB5fNA9kJJBuLU%2FTYL%2BBRgPJ3%2Fe62QdRLUopuaruwLiFkZ5tt0T7PucNmz7grptfKd75xisBC8oVUkU5ny7AFnlyBsNYjnkuuRA%2FLYF38lzr7zN0qiMC6azJbbSvA5ZrTq%2B7Jwp7dA22OvZOblPkywtnRRXSwc4N0IGskScNvAobThk8L4TlJ6mSRReq92MSSJ5g8i6Dl3CkRqk4LImg7PY99c9PJDAolYCq%2F72iiC%2BCOGSX7FjmmQ5Gnm3D0jpmT783R7El7atjXxlZZdkFrAxdB8MWRWKUse14837YG%2F7jZvT8nO7kDvSRFaKRmdvs48PmW6eO5KVUYCEWzweqJO2EVdUc%2FaDexIsyvenmfGT90ZYRcKUbQBNO0YST69oYGZDIi6JxYnqU%2FS3OlzL2K7aCWdGeWplSKjFdcvE%2FRQ5DR2%2BtfeIn9wJWMx%2FC4CoDYFJr2%2FDREKSMA1gu9Ac4ImYwrxAEpgGSsuS7Aex35R6%2B7%2BmHDCX5dSzBjqEAmEFfkqjmxfUqd78qznWngQdrz2tZw9Gr7obXMekagPbOh3s37cG0mmPDRrZa3mxPsWbaob9EFMuCmuLgSOgyZVbcLKblPxafuzVa2dmGuGdHvPSgAd5Cn3CLtqpEuANuX0p%2B2SeIcyE2%2FM%2FtzfbmZdUQoX2baw29Tn4GkBRB%2Fj%2BW2wAbsSlca91qWFtlKI7zo6qyWTRk4keDwFPNXvAHx5dAYQ1onQoOM7TWQTB%2BmDWaQteFePf1zQHQJ%2F0Y0PqdcVNcnipFFQAP8OxIaWntzauSWX2%2F1lK6d0CwB1IQmp%2F%2BbkDCMvUKUE%2BLr9P9TzefViiEgcIssvSm2TZnwrITlTiqaUS&X-Amz-Signature=d2cd5313a7421ca19658e5b88c04533025a66db42822a13332c708ee25387b26";


//
const Test = () => {
        //meta title
        document.title="Test";
        //code
        
  return (
  <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TEST" breadcrumbItem="Test page" />
          <Row>
            <Col lg="12">
            <Card>
            <h1>direct link</h1>
            <img src="https://medstock-staging144819-staging.s3.us-east-1.amazonaws.com/public/uploads/b4c8d4f8-3001-702a-6943-c07237e25f6b/3378ab3a-ed09-4a31-9410-fe246d0dc7a5/2024-06-20/foto-magazzino.jpg?x-amz-content-sha256=e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAS5PQIXLKIHJVG55A%2F20240621%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240621T080318Z&X-Amz-SignedHeaders=host&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELD%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQCzO%2FM2rC6K2hzDDzSquKUkB8pwcQqRhN72%2ByrFRRipnAIhAI0r%2B9DvHbCbVu0iyg2KXZCzJh8MzCWjGvFlhmpWk3x6KsQECFkQABoMMjAwNzU2NzM4NzcyIgwWp%2F%2BQayeBkpPSIJwqoQQvzmXp4v4McwEjcJnoaii6lQfoYUmebpe8DPrKzszdDW1BUvAXM3sPxr%2F1zHY4ZTDsItkGkZQ%2BO3Q5OZ25IavlKp%2B3xfJGZ4gIGSghcniTZ1bigpxh0MFwLIV9OpGLT%2BdJAKrzEA%2FeiXR4ZRRjzpyUSjqri0Ti%2BfS3mKQAlkqTm3EhuWQt5rHSOmSj044GcixGzCkRQG67f1FxaFFijFTp0X%2FPtmTHK6dB3n1K3rB5fNA9kJJBuLU%2FTYL%2BBRgPJ3%2Fe62QdRLUopuaruwLiFkZ5tt0T7PucNmz7grptfKd75xisBC8oVUkU5ny7AFnlyBsNYjnkuuRA%2FLYF38lzr7zN0qiMC6azJbbSvA5ZrTq%2B7Jwp7dA22OvZOblPkywtnRRXSwc4N0IGskScNvAobThk8L4TlJ6mSRReq92MSSJ5g8i6Dl3CkRqk4LImg7PY99c9PJDAolYCq%2F72iiC%2BCOGSX7FjmmQ5Gnm3D0jpmT783R7El7atjXxlZZdkFrAxdB8MWRWKUse14837YG%2F7jZvT8nO7kDvSRFaKRmdvs48PmW6eO5KVUYCEWzweqJO2EVdUc%2FaDexIsyvenmfGT90ZYRcKUbQBNO0YST69oYGZDIi6JxYnqU%2FS3OlzL2K7aCWdGeWplSKjFdcvE%2FRQ5DR2%2BtfeIn9wJWMx%2FC4CoDYFJr2%2FDREKSMA1gu9Ac4ImYwrxAEpgGSsuS7Aex35R6%2B7%2BmHDCX5dSzBjqEAmEFfkqjmxfUqd78qznWngQdrz2tZw9Gr7obXMekagPbOh3s37cG0mmPDRrZa3mxPsWbaob9EFMuCmuLgSOgyZVbcLKblPxafuzVa2dmGuGdHvPSgAd5Cn3CLtqpEuANuX0p%2B2SeIcyE2%2FM%2FtzfbmZdUQoX2baw29Tn4GkBRB%2Fj%2BW2wAbsSlca91qWFtlKI7zo6qyWTRk4keDwFPNXvAHx5dAYQ1onQoOM7TWQTB%2BmDWaQteFePf1zQHQJ%2F0Y0PqdcVNcnipFFQAP8OxIaWntzauSWX2%2F1lK6d0CwB1IQmp%2F%2BbkDCMvUKUE%2BLr9P9TzefViiEgcIssvSm2TZnwrITlTiqaUS&X-Amz-Signature=d2cd5313a7421ca19658e5b88c04533025a66db42822a13332c708ee25387b26"></img>
        
            </Card>
            
                <Card>
                    <h1>immagine presa con Amplify UI</h1>
                    <StorageImage alt="" path="public/uploads/b4c8d4f8-3001-702a-6943-c07237e25f6b/3378ab3a-ed09-4a31-9410-fe246d0dc7a5/2024-06-20/foto-magazzino.jpg" />

             
                </Card>

            </Col>
            </Row>
        </Container>
      </div>        
  </React.Fragment>
  )
};

export default Test;
