// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { InvoiceCounter, Fatture, Immagini, Paziente, Utente, Contatto, Prodotti1, Magazzino1 } = initSchema(schema);

export {
  InvoiceCounter,
  Fatture,
  Immagini,
  Paziente,
  Utente,
  Contatto,
  Prodotti1,
  Magazzino1
};