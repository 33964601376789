import PropTypes from 'prop-types';
import React, { useState , useMemo} from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Routes, Route } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

import { GlobalDataProvider } from "./contexts/GlobalDataContext";

import { ToastContainer } from 'react-toastify';

//AWS
import { Amplify, API, graphqlOperation  } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
Amplify.configure(amplifyconfig);
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
//import awsExports from './aws-exports'; // Path to your aws-exports.js file
//Amplify.configure(awsExports);
//import { withAuthenticator } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';

import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('it');

Amplify.configure(config);


const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {

  const selectLayoutState = (state) => state.Layout;
  const [magazzino, setMagazzino] = useState([]);
  const [nomiProdotti, setNomiProdotti] = useState([]);
  const LayoutProperties = createSelector(
    selectLayoutState,
      (layout) => ({
        layoutType: layout.layoutType,
      })
  );
  const { layoutType } = useSelector(LayoutProperties);
  const Layout = getLayout(layoutType);

  return (
    <GlobalDataProvider value={{ magazzino, setMagazzino, nomiProdotti, setNomiProdotti }}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    {useMemo(() => (
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authenticator>
                <Layout>{route.component}</Layout>
                </Authenticator>  
              }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    ), [publicRoutes, authProtectedRoutes])}
    </GlobalDataProvider>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;