
import PropTypes from "prop-types";
import React from "react";
import {
  Container, Row, Col, Button, Card, CardBody, CardTitle, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//
import SimpleDataTable from './datatables';
//


const Archivi = () => {
  return 
  <React.Fragment>
              <p>
              <SimpleDataTable />
              </p>
  </React.Fragment>
};

export default Archivi;
