import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import { filterItemsWithNullDataScarico } from "helpers/magazzino_helper";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";

const BarChart = ({ dataColors }) => {
    const {magazzino, nomiProdotti } = useContext(GlobalDataContext);

    // Function to get chart data
    function getChartData(magazzino, nomiProdotti) {
        console.log('getChartData');
        // Filter products not downloaded
        const filteredArray = filterItemsWithNullDataScarico(magazzino, nomiProdotti);

        const gtinCounts = {};
        // Count how many times each GTIN appears
        filteredArray.forEach(item => {
            gtinCounts[item.GTIN] = (gtinCounts[item.GTIN] || 0) + 1;
        });

        //Prodotti
        const findNomeByGTIN = (gtin) => {
        const items = nomiProdotti;
        const product = items.find(item => item.GTIN === gtin);
        return product ? product.Nome : null; // Return null or any default string if not found
        };
        const categories = Object.keys(gtinCounts).map(gtin => findNomeByGTIN(gtin) || gtin); // Default to GTIN if no name mapping exists
        // Convert counts object to arrays for the chart, substituting GTIN with product names
        //const categories = Object.keys(gtinCounts).map(gtin => gtinToProductName[gtin] || gtin); // Default to GTIN if no name mapping exists
        const data = Object.values(gtinCounts);

        return { categories, data };
    }

    const spineareaChartColors = getChartColorsArray(dataColors);

    function getSeries(magazzino) {
        return [{
            name: 'totale',  // This sets the series name to an empty string. Change '' to your preferred series name if needed.
            data: getChartData(magazzino, nomiProdotti).data,
        }];
    }

    function getOptions(magazzino) {
        return {
            chart: {
                toolbar: {
                    show: true,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: spineareaChartColors,
            grid: {
                borderColor: "#f1f1f1",
            },
            xaxis: {
                categories: getChartData(magazzino, nomiProdotti).categories,
            },
        }
    }

  return (
      <ReactApexChart options={getOptions(magazzino)} series={getSeries(magazzino)} type="bar" height="350" />
  );
};


export default BarChart;
