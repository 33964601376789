import React from "react"
import { Container, Row, Col, Badge, Table } from "reactstrap"
import { Link } from "react-router-dom"
//import { blogs } from "../../../common/data";
import codiceudi from "../../../assets/images/iabaduu/CodiceUDI.png";

const Blog = () => {

  return (
    <React.Fragment>
      <section className="section bg-white" id="news">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">UDI</div>
               <h4>La Normativa Europea UDI - MDR sulla tracciabilità dei dispositivi medici</h4>
              </div>
            </Col>
          </Row>

          <Row>
          <Col xl="6" sm="6">
                <div className="text-muted">
                <p>
                Con l'entrata in vigore della nuova normativa europea <a href="https://eur-lex.europa.eu/legal-content/IT/TXT/HTML/?uri=CELEX:02017R0745-20240709">UE Medical Device Regulation (EU MDR)</a> sulla tracciabilità dei dispositivi medici, i medici sono obbligati a:
                </p>  
                <p>
                <ul>
                    <li>Verificare l'etichettatura UDI (Unique Device Identification) su tutti i dispositivi medici utilizzati nella pratica clinica. Questo garantisce la conformità del dispositivo agli standard di tracciabilità e sicurezza richiesti dalla normativa.</li>
                    <li>Registrare e documentare l'UDI dei dispositivi utilizzati sui pazienti. Questo permette di assicurare la tracciabilità del dispositivo e facilita eventuali richiami o interventi successivi se necessario.</li>
                </ul>
                </p>
                <p>
                Il termine “dispositivo medico” copre una vasta gamma di oggetti, inclusi apparecchi e attrezzature a contatto diretto con il paziente per scopi di diagnosi o trattamento di una condizione clinica, e include pertanto molti strumenti comunemente usati dai medici.
                </p>
                </div>
              </Col>
              <Col xl="6" sm="6">
              <div className="text-center mb-5">
                    <h4>Composizione del codice</h4>
               </div>    
                  <div className="d-flex align-items-center">
                    <img src={codiceudi} alt="codiceudi" className="img-fluid" width="100%"></img>
                </div>
              </Col>
          </Row>
          <Row>
          <Col lg="12">
          <div className="text-muted">
          Le scadenze per la conformità alla normativa MDR sulla codifica UDI dei dispositivi medici variano in base alla classe del dispositivo, che è determinata dal livello di rischio associato. In particolare, i dispositivi di Classe III presentano un rischio elevato per il paziente.
          <ul>
          <li>Classe III: include dispositivi come pacemaker impiantabili, generatori di impulsi, test diagnostici per l’HIV, defibrillatori automatici esterni, impianti endossei e lenti a contatto per uso prolungato.</li>
          <li>Classe II a / b: comprende dispositivi quali aghi per agopuntura, lenti a contatto giornaliere, sedie a rotelle motorizzate, pompe per infusione, teli chirurgici e sistemi transponder a radiofrequenza impiantabili per l'identificazione del paziente e delle informazioni sanitarie.</li>
          <li>Classe I: include dispositivi a basso rischio come bende elastiche, guanti in lattice e strumenti chirurgici portatili.</li>
          </ul>
          </div>
          <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr className="table-light">
                          <th scope="row">5 Maggio 2017</th>
                          <td>Pubblicazione regolamento EU</td>
                          <td>Codifica del dispositivo medico</td>
                          <td>Marcatura diretta del dispositivo medico</td>
                          <td>Codifica del dispositivo medico-diagnostico in vitro</td>
                        </tr>

                        <tr className="table-success">
                          <th scope="row">26 Maggio 2020</th>
                          <td>Assegnazione UDI e registrazione su <a href="https://ec.europa.eu/tools/eudamed/">EUDAMED</a></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>

                        <tr className="table-info">
                          <th scope="row">26 Maggio 2021</th>
                          <td></td>
                          <td>Classe III MD</td>
                          <td></td>
                          <td></td>
                        </tr>

                        <tr className="table-warning">
                          <th scope="row">26 Maggio 2023</th>
                          <td></td>
                          <td>Classe II a/b MD</td>
                          <td>Classe III MD</td>
                          <td>Classe D IVD</td>
                        </tr>

                        <tr className="table-danger">
                          <th scope="row">26 Maggio 2025</th>
                          <td></td>
                          <td>Classe I MD</td>
                          <td>Classe II a/b MD</td>
                          <td>Classe C&B IVD</td>
                        </tr>
                        <tr className="table-primary">
                          <th scope="row">26 Maggio 2027</th>
                          <td></td>
                          <td></td>
                          <td>Classe I MD</td>
                          <td>Classe A IVD</td>
                        </tr>
                      </tbody>
                    </Table>
          </div>
        </Col>
        </Row>  

        </Container>
      </section>
    </React.Fragment>
  )
}

export default Blog
