// Import necessary modules from AWS Amplify
import { getUrl } from 'aws-amplify/storage';


async function fetchAndFormatImages() {
/*  
  try {
    // Fetch image data from the database using GraphQL query with a specific filter

    
    const listImages = await client.graphql({
      query: queries.listImmaginis,
      filter: { immaginiPazienteId: '3378ab3a-ed09-4a31-9410-fe246d0dc7a5' }
    });
    
    console.log('Elenco immagini: ', listImages); // Log the list of images

    // Extract the image items from the fetched data
    const imageItems = listImages.data.listImmaginis.items;

    // Use Promise.all to handle asynchronous operations for each image item
    const formattedImages = await Promise.all(imageItems.map(async (image) => {
      // Generate a signed URL for each image using getUrl method
      const signedUrlResult = await getUrl({
        path: image.link,
        options: {
          validateObjectExistence: false,
          expiresIn: 3600,
          useAccelerateEndpoint: true,
        },
      });

      // Return a formatted image object
      return {
        id: image.id, //Use image id for deletion
        src: signedUrlResult.url, // Use the signed URL as the src
        original: signedUrlResult.url, // Use the signed URL as the original
        width: parseInt(image.width, 10), // Parse the width to an integer
        height: parseInt(image.height, 10), // Parse the height to an integer
        tags: [
          { value: image.dataimmagine || 'No Date', title: 'Data' }, // Add a tag with the image date
        ],
        caption: `${image.dataimmagine || 'No Date'} - pinco pallino`, // Create a caption with the image date
      };
    }));

    console.log('Formatted Images: ', formattedImages); // Log the formatted images

    return formattedImages; // Return the formatted images array
  } catch (error) {
    console.error('Error fetching or formatting images:', error); // Log any errors
  }
*/
  }

// Execute the function and export the result as images
export const images = await fetchAndFormatImages();
