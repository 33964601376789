import React from "react"
import { Container, Row, Col } from "reactstrap"

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "../../../../node_modules/swiper/swiper.scss";

const RoadMap = () => { 

  return (
    <React.Fragment>
      <section className="section bg-white" id="roadmap">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Timeline</div>
                <h4>La storia dell'UDI</h4>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg="12">
              <div className="hori-timeline">
                <Swiper
                  slidesPerView={1}
                  // spaceBetween={10}
                  navigation
                  pagination={{                   
                    clickable: true,
                  }}
                  breakpoints={{
                    678: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    }
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="owl-carousel owl-theme events navs-carousel" id="timeline-carousel">
                  <SwiperSlide className="item event-list">
                    <div>
                      <div className="event-date">
                        <div className="text-primary mb-1">2017</div>
                        <h5 className="mb-4">regolamento EU dispositivi medici</h5>
                      </div>
                      <div className="event-down-icon">
                        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                      </div>

                      <div className="mt-3 px-3">
                        <p className="text-muted">immediatamente operativo anche se è prevista una gradualità di attuazione (art.121) con rivalutazione dei risultati ottenuti prevista per il 27 maggio 2027</p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item event-list">
                    <div>
                      <div className="event-date">
                        <div className="text-primary mb-1">2023</div>
                        <h5 className="mb-4">D.Lgs.n.137/22 all’art.15</h5>
                      </div>
                      <div className="event-down-icon">
                        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                      </div>

                      <div className="mt-3 px-3">
                        <p className="text-muted">obbligo di identificazione, tracciabilità e nomenclatura dei dispositivi</p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item event-list active">
                    <div>
                      <div className="event-date">
                        <div className="text-primary mb-1">15 Gennaio 2024</div>
                        <h5 className="mb-4">Obbligo registrazione UDI</h5>
                      </div>
                      <div className="event-down-icon">
                        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                      </div>

                      <div className="mt-3 px-3">
                        <p className="text-muted">Decorrenza obbligo di registrazione e conservazione dell’UDI</p>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="item event-list">
                    <div>
                      <div className="event-date">
                        <div className="text-primary mb-1">31 Dicembre 2027</div>
                        <h5 className="mb-4">Termine DM senza UDI</h5>
                      </div>
                      <div className="event-down-icon">
                        <i className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                      </div>

                      <div className="mt-3 px-3">
                        <p className="text-muted">Termine circolazione dispositivi medici senza codice UDI</p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default RoadMap
