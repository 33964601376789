import React from "react";

//Import Components
import Navbar from "./Navbar/Navbar"
import Section from "./HeroSection/Section"
import CardsMini from "./HeroSection/cards-mini"
import AboutUs from "./AboutUs/about-us"
import Features from "./Features/features"
import RoadMap from "./RoadMap/road-map"
import OurTeam from "./Team/our-team"
import Blog from "./Blog/blog"
import FAQs from "./Faqs/FAQs"
import Footer from "./Footer/footer"
import Sottoscrizione from "../Authentication/Sottoscrizione"

const PublicSite = () => {
  
  //meta title
  document.title="MedStock - Magazzino codici UDI";

  return (
    <React.Fragment>
      {/* import navbar */}
      <Navbar />

      {/* Hero section */}
      <Section />

      {/* mini cards */}
      <CardsMini />

      {/* aboutus */}
      <AboutUs />

      {/* features */}
      <Features />

      {/* BUY */}
      <Sottoscrizione />

      
      {/* faqs */}
      <FAQs />

      {/* blog Blog */}
      <Blog />

      {/* our team OurTeam */}
      <OurTeam />

     {/* roadmap */}
     <RoadMap />

      
      {/* footer */}
      <Footer />
    </React.Fragment>
  )
}

export default PublicSite
