import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ScaricoToastMsg = ({ closeToast, toastProps, data1, data2, data3, data4, data5, data6 }) => (
    <div>
      <h3>Prodotto Scaricato</h3>
      <ul>
        <li>Data Scarico: {data1}</li>
        <li>Data Carico: {data2}</li>
        <li>GTIN: {data3}</li>
        <li>Lotto: {data4}</li>
        <li>Scadenza: {data5}</li>
        <li>Seriale: {data6}</li>
      </ul>
    </div>
  );
  const ScaricoToastNotify = (data1, data2, data3, data4, data5, data6) => {
    console.log('TOAST')
    toast.success(<ScaricoToastMsg data1={data1} data2={data2} data3={data3} data4={data4} data5={data5} data6={data6}/>);
  };
  const ScaricoToastErrore = (errorMessage) => {
    toast.error(errorMessage || "Prodotto già scaricato o errore nei dati", {
      autoClose: 4000,
      position: "top-center",
    });
  };

  const CaricoToastMsg = ({ closeToast, toastProps, data1, data2, data3, data4, data5 }) => (
    <div>
      <h3>Prodotto Caricato</h3>
      <ul>
        <li>Data Carico: {data1}</li>
        <li>GTIN: {data2}</li>
        <li>Lotto: {data3}</li>
        <li>Scadenza: {data4}</li>
        <li>Seriale: {data5}</li>
      </ul>
    </div>
  );
  const CaricoToastNotify = (data1, data2, data3, data4, data5) => {
    toast.success(<CaricoToastMsg data1={data1} data2={data2} data3={data3} data4={data4} data5={data5} />);
  };
  const CaricoToastErrore = (errorMessage) => {
    toast.error(errorMessage || "Errore nei dati", {
      autoClose: 4000,
      position: "top-center",
    });
  };
  const CaricoToastDuplicato = (errorMessage) => {
    toast.error(errorMessage || "Prodotto presente in magazzino", {
      autoClose: 4000,
      position: "top-center",
    });
  };
  const UpdateUtenteOk = (successMessage) => {
    toast.success(successMessage || "Dati Utente aggiornati", {
      autoClose: 4000,
      position: "top-center",
    });
  };
  const UpdateUtenteKO = (errorMessage) => {
    toast.error(errorMessage || "Errore nell'aggiornamento dati", {
      autoClose: 4000,
      position: "top-center",
    });
  };

  export {ScaricoToastMsg, ScaricoToastNotify, ScaricoToastErrore, CaricoToastMsg, CaricoToastNotify, CaricoToastErrore, CaricoToastDuplicato, UpdateUtenteOk, UpdateUtenteKO}