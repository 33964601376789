import React from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import FeatureBox from "./feature-box";

//Import images
import Slidewithcontrol from "../slidewithcontrol";
import feature2 from "../../../assets/images/iabaduu/grafico.png";
import feature1 from "../../../assets/images/iabaduu/udi_barcode.jpg";

const Features = () => {
  const features1 = [
    { id: 1, desc: "Riconoscimento automatico dei paramentri UDI" },
    { id: 2, desc: "Inserimento e modifica anche manuale" },
  ];
  const features2 = [
    { id: 1, desc: "Visualizzazione immediata giacenze" },
    { id: 2, desc: "Conservazione magazzino a noma di legge" },
  ];

  return (
    <React.Fragment>
      <section className="section" id="features">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Funzionalità</div>
                <h4>Caratteristiche chiave del sistema</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="6" sm="8">
              <div>
              <Slidewithcontrol />
              </div>
            </Col>
            <Col md="5" className="ms-auto">
              {/* featurebox */}
              <FeatureBox
                num="01"
                title="Scansione QR"
                features={features1}
                desc="Scansione del codice UDI tramite barcode scanner o inserimento manuale dei dati"
              />
            </Col>
          </Row>

          <Row className="align-items-center mt-5 pt-md-5">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="02"
                title="Magazzino UDI"
                features={features2}
                desc="Gestione immedita del magazzino medicinali in capo al singolo medico"
              />
            </Col>
            <Col md="6" sm="8" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={feature2}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
