/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://vae4zyhvbvaknbl6uzqchne4d4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-zpf57z6ujnhx7igdkbvg2aghqa",
    "aws_cloud_logic_custom": [
        {
            "name": "stripeapi",
            "endpoint": "https://p8rtru80pj.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:80e25d1d-b9fa-4b04-b9e8-b419c0d9d813",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_dzIJoeJ1S",
    "aws_user_pools_web_client_id": "2qurj1vdgai4aor8oukaorcq0b",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "medstock-staging0f416-live",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
