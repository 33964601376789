import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import stripelogo from "../../assets/images/iabaduu/Stripe-logo-official.png";
import { generateClient } from 'aws-amplify/api';
//import * as queries from '../../graphql/queries';  // Ensure fatturesByClienteEmail is imported

const client = generateClient();

const UserSubscription = () => {
  const [fattureData, setFattureData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFattureData = async () => {
    try {
      // Retrieve the authUser from localStorage and parse it to get the email
      const authUser = localStorage.getItem('authUser');
      
      if (authUser) {
        const user = JSON.parse(authUser); // Parse the string to an object
        const userEmail = user.email; // Access the 'email' field
        console.log('User Email:', userEmail); // Log the userEmail

        // Now, fetch fatture using the user email (cliente_email)
        const fattureResponse = await client.graphql({
          query: queries.fatturesByClienteEmail,
          variables: { cliente_email: userEmail },  // Use the email to match cliente_email
        });

        const fatture = fattureResponse.data.fatturesByClienteEmail.items; // Get the items array
        if (fatture) {
          setFattureData(fatture);
        } else {
          console.error("No fatture data found");
        }
      } else {
        console.log('No email found in localStorage');
      }
    } catch (err) {
      console.error("Error fetching fatture data", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFattureData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Utente" breadcrumbItem="Abbonamento" />

          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <h4>Il tuo abbonamento</h4>
                  {loading ? (
                    <p>Loading...</p>
                  ) : fattureData.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ID Fattura</th>
                          <th>Data</th>
                          <th>Importo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fattureData.map((fattura) => (
                          <tr key={fattura.id}>
                            <td>{fattura.id}</td>
                            <td>{new Date(fattura.data_fattura).toLocaleDateString()}</td>
                            <td>{fattura.importo_totale}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>Nessun dato disponibile</p>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col lg="6">
              <Card>
                <CardBody>
                  <h4>Gestisci il tuo abbonamento</h4>
                  <p>
                    MedStock utilizza il sistema di pagamento Stripe per garantire la massima sicurezza delle transazioni.
                  </p>
                  <a href="https://billing.stripe.com/p/login/4gwcPk0099ASc6cbII">
                    <img className="center" width="200" src={stripelogo} alt="stripe" />
                  </a>
                  <p>
                    Accedi al <a href="https://billing.stripe.com/p/login/4gwcPk0099ASc6cbII">portale dedicato</a> per gestire il tuo abbonamento, i dati di pagamento e l'annullamento dell'abbonamento.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserSubscription;
